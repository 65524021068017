import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import type { ActivityDriverValidation } from 'shared/lib/types';

type Props = { activityDriverValidation?: ActivityDriverValidation | null };

function ActivityDriverCounts(props: Props) {
  const { activityDriverValidation } = props;

  if (
    activityDriverValidation === undefined ||
    activityDriverValidation === null ||
    (activityDriverValidation.activity_driver_count === null &&
      activityDriverValidation.contract_budget_record_count === null)
  ) {
    return null;
  }

  const { activity_driver_count, contract_budget_record_count } =
    activityDriverValidation;

  const finalActivityDriverCount =
    activity_driver_count === null ? 0 : activity_driver_count;
  const finalContractBudgetRecordCount =
    contract_budget_record_count === null ? 0 : contract_budget_record_count;

  return (
    <Box>
      <Typography color="text.secondary" variant="overline">
        Activity Drivers
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          mb: 1,
        }}
      >
        {finalActivityDriverCount === finalContractBudgetRecordCount ? (
          <CheckCircleIcon color="success" />
        ) : (
          <WarningIcon color="warning" />
        )}
        <Typography
          sx={{ ml: 0.5 }}
          variant="subtitle1"
        >{`${finalActivityDriverCount}/${finalContractBudgetRecordCount} assigned`}</Typography>
      </Box>
    </Box>
  );
}

export default ActivityDriverCounts;
