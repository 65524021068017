import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import NavigationTabs from 'shared/components/tabs/NavigationTabs';

import { selectForecast } from 'forecasting/state/slices/forecastSlice';
import {
  getForecastDetailTab,
  getForecastParametersTab,
  getForecastSummaryTab,
  getForecastTab,
  getForecastingExpensesTab,
} from 'routes';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';

function ForecastingTabs() {
  const { activeTab } = useParams<{
    contractContainerSlug: string;
    activeTab: string;
  }>();
  const forecast = useSelector(selectForecast);
  const expensesTabEnabled = useFeatureFlag('forecasting_expenses');

  const forecastingTabs = [
    {
      link: getForecastSummaryTab(forecast.trace_id),
      label: 'Summary',
      key: 'summary',
    },
    {
      link: getForecastDetailTab(forecast.trace_id),
      label: 'Detail',
      key: 'detail',
    },
    ...(expensesTabEnabled
      ? [
          {
            link: getForecastingExpensesTab(forecast.trace_id),
            label: 'Expenses',
            key: 'expenses',
          },
        ]
      : []),
    {
      link: getForecastParametersTab(forecast.trace_id),
      label: 'Parameters',
      key: 'parameters',
    },
  ];

  return (
    <NavigationTabs
      activeTab={activeTab}
      generateUrl={(newTab) => getForecastTab(forecast.trace_id, newTab)}
      tabs={forecastingTabs}
    />
  );
}

export default ForecastingTabs;
