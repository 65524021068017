import type {
  ActivityDriverValidation,
  AssumptionGroupResponse,
  ContractVersionRequestParams,
  ContractVersionResponse,
  CroCostCategory,
  CroInvestigatorFeesAdjustments,
  CroReconActivityGroup,
  CroReconBottomLineAdjustmentResolution,
  CroReconLineMatchings,
  CroReconResponse,
  CroReconTopsideAdjustment,
  InvestigatorFeesGridResponse,
  RegionAssumptionGroups,
  RegionResponse,
  TraceId,
  UpdateRegionsRequest,
} from 'shared/lib/types';

import type { ApiJSON } from '../apiJSON';
import { ADMINISTRATIVE_OR_PROCEDURE_CATEGORIES_TAG } from './administrativeorprocedurecategories';
import {
  ACCRUALS_GRID,
  ALL_CONTRACTS,
  BS_FLUX_GRID,
  CRO_EXPENSE_SUMMARY,
  JOURNAL_ENTRY_GRID,
  OCC_EXPENSE_SUMMARY,
  PERIOD_OCC_EXPENSES,
  PERIOD_OCC_RECON,
  TRIAL_EXPENSE_SUMMARY,
} from './apiTags';
import { TAG as ASSUMPTION_GROUP } from './assumptiongroups';
import constructApi from './constructApi';
import { CONTRACT_CONTAINERS } from './contractcontainers';
import { TAG as REGIONGROUPS } from './regiongroups';
import { CONTRACT_REGIONS as REGION } from './regions';

const PATH = 'contracts';

export const CONTRACT_RECON = 'CONTRACT_RECON';
export const CONTRACT_EXPENSES = 'CONTRACT_EXPENSES';
export const CONTRACT_INVESTIGATOR_FEES = 'CONTRACT_INVESTIGATOR_FEES';
export const CONTRACT_INVESTIGATOR_FEES_RECON =
  'CONTRACT_INVESTIGATOR_FEES_RECON';
export const CONTRACT_ACTIVITY_DRIVER_COMPLETED_COUNT =
  'CONTRACT_ACTIVITY_DRIVER_COMPLETED_COUNT';
export const CONTRACTS = 'CONTRACTS';

const apiEndpointFactory = constructApi<ContractVersionResponse>(PATH).withTags(
  CONTRACTS,
  [
    CONTRACT_ACTIVITY_DRIVER_COMPLETED_COUNT,
    CONTRACT_CONTAINERS,
    CONTRACT_EXPENSES,
    CONTRACT_RECON,
    CONTRACT_INVESTIGATOR_FEES,
    CONTRACT_INVESTIGATOR_FEES_RECON,
    ASSUMPTION_GROUP,
    REGION,
    REGIONGROUPS,
    ADMINISTRATIVE_OR_PROCEDURE_CATEGORIES_TAG,
    PERIOD_OCC_EXPENSES,
    PERIOD_OCC_RECON,
    CRO_EXPENSE_SUMMARY,
    OCC_EXPENSE_SUMMARY,
    ACCRUALS_GRID,
    JOURNAL_ENTRY_GRID,
    BS_FLUX_GRID,
    TRIAL_EXPENSE_SUMMARY,
  ],
);
export const api = apiEndpointFactory.inject(() => ({
  createContract: apiEndpointFactory.create<ContractVersionRequestParams>([
    CONTRACT_CONTAINERS,
  ]),
  deleteContract: apiEndpointFactory.delete([CONTRACT_CONTAINERS]),
  getContractWithStatus:
    apiEndpointFactory.getExtraRouteTwoParameters<ContractVersionResponse>(
      'contract_with_status',
      [CONTRACTS],
    ),
  getContractsByContractContainer:
    apiEndpointFactory.getBy('contract_container'),
  getContractsByGlAccount: apiEndpointFactory.getBy('gl_account'),
  getContractExpenseGrid: apiEndpointFactory.getExtraRouteThreeParameters<
    ApiJSON,
    TraceId,
    CroCostCategory
  >('expense_grid', [CONTRACT_EXPENSES]),
  getContractReconGrid: apiEndpointFactory.getExtraRouteThreeParameters<
    CroReconResponse,
    TraceId,
    CroCostCategory
  >('cro_recon_grid', [CONTRACT_RECON]),
  getContractInvestigatorFeesGrid:
    apiEndpointFactory.getExtraRouteTwoParameters<InvestigatorFeesGridResponse>(
      'cro_investigator_fees_grid',
      [CONTRACT_INVESTIGATOR_FEES],
    ),
  getContractInvestigatorFeesReconGrid:
    apiEndpointFactory.getExtraRouteTwoParameters<InvestigatorFeesGridResponse>(
      'cro_investigator_fees_recon',
      [CONTRACT_INVESTIGATOR_FEES_RECON],
    ),
  getContractReconLineMatching: apiEndpointFactory.getExtraRouteThreeParameters<
    CroReconLineMatchings,
    TraceId,
    CroCostCategory
  >('cro_recon_line_matching', [CONTRACT_RECON]),
  getCompletedActivityDriverCount:
    apiEndpointFactory.getExtraRouteTwoParameters<ActivityDriverValidation>(
      'get_completed_activity_driver_count',
      [CONTRACT_ACTIVITY_DRIVER_COMPLETED_COUNT],
    ),
  updateRegions: apiEndpointFactory.update<UpdateRegionsRequest>(
    [REGION, REGIONGROUPS],
    'update_regions',
  ),
  updateAssumptions: apiEndpointFactory.update<RegionAssumptionGroups>(
    [ASSUMPTION_GROUP, REGION, REGIONGROUPS],
    'update_assumptions',
  ),
  updateContract: apiEndpointFactory.update<ContractVersionRequestParams>([
    // when currency is changed, we need to invalidate cache of a lot of grids
    CONTRACTS,
    CONTRACT_CONTAINERS,
    CONTRACT_EXPENSES,
    CONTRACT_RECON,
    CONTRACT_INVESTIGATOR_FEES,
    CONTRACT_INVESTIGATOR_FEES_RECON,
    PERIOD_OCC_EXPENSES,
    PERIOD_OCC_RECON,
    CRO_EXPENSE_SUMMARY,
    OCC_EXPENSE_SUMMARY,
    ACCRUALS_GRID,
    JOURNAL_ENTRY_GRID,
    BS_FLUX_GRID,
    TRIAL_EXPENSE_SUMMARY,
    ALL_CONTRACTS,
  ]),
  updateContractReconLineMatching:
    apiEndpointFactory.updateThreeParameters<CroReconLineMatchings>(
      [CONTRACT_RECON],
      'cro_recon_line_matching',
    ),
  upsertContractReconActivityGroup:
    apiEndpointFactory.updateTwoParameters<CroReconActivityGroup>(
      [CONTRACT_RECON],
      'cro_recon_activity_group',
    ),
  updateContractReconTopsideAdjustment:
    apiEndpointFactory.updateThreeParameters<CroReconTopsideAdjustment>(
      [
        CONTRACT_RECON,
        CONTRACT_INVESTIGATOR_FEES_RECON,
        CRO_EXPENSE_SUMMARY,
        ACCRUALS_GRID,
        JOURNAL_ENTRY_GRID,
        BS_FLUX_GRID,
      ],
      'cro_recon_topside_adjustment',
    ),
  updateContractReconBottomLineAdjustmentResolution:
    apiEndpointFactory.updateTwoParameters<CroReconBottomLineAdjustmentResolution>(
      [
        CONTRACT_RECON,
        CRO_EXPENSE_SUMMARY,
        ACCRUALS_GRID,
        JOURNAL_ENTRY_GRID,
        BS_FLUX_GRID,
        TRIAL_EXPENSE_SUMMARY,
      ],
      'cro_recon_bottom_line_adjustment_resolution',
    ),
  upsertContractInvestigatorFeesAdjustments:
    apiEndpointFactory.updateTwoParameters<CroInvestigatorFeesAdjustments>(
      [CONTRACT_INVESTIGATOR_FEES_RECON],
      'cro_investigator_fees_adjustments',
    ),
  getClosedPeriodAssumptionGroupsByContractVersion:
    apiEndpointFactory.getExtraRoute<AssumptionGroupResponse[]>(
      'get_closed_period_assumption_groups',
    ),
  getClosedPeriodRegionsByContractVersion: apiEndpointFactory.getExtraRoute<
    RegionResponse[]
  >('get_closed_period_regions_by_contract_version'),
  getClosedPeriodRegionGroupsByContractVersion:
    apiEndpointFactory.getExtraRoute<RegionResponse[]>(
      'get_closed_period_region_groups_by_contract_version',
    ),
}));

export const {
  useGetContractsByContractContainerQuery,
  useGetCompletedActivityDriverCountQuery,
  useLazyGetCompletedActivityDriverCountQuery,
  useGetContractWithStatusQuery,
  useGetContractsByGlAccountQuery,
  useGetContractExpenseGridQuery,
  useLazyGetContractExpenseGridQuery,
  useGetContractInvestigatorFeesGridQuery,
  useLazyGetContractInvestigatorFeesGridQuery,
  useGetContractInvestigatorFeesReconGridQuery,
  useLazyGetContractInvestigatorFeesReconGridQuery,
  useGetContractReconGridQuery,
  useLazyGetContractReconGridQuery,
  useGetContractReconLineMatchingQuery,
  useUpdateContractMutation,
  useUpdateContractReconLineMatchingMutation,
  useUpsertContractReconActivityGroupMutation,
  useUpdateContractReconTopsideAdjustmentMutation,
  useUpdateContractReconBottomLineAdjustmentResolutionMutation,
  useUpsertContractInvestigatorFeesAdjustmentsMutation,
  useUpdateRegionsMutation,
  useUpdateAssumptionsMutation,
  useCreateContractMutation,
  useDeleteContractMutation,
  useGetClosedPeriodAssumptionGroupsByContractVersionQuery,
  useGetClosedPeriodRegionsByContractVersionQuery,
  useGetClosedPeriodRegionGroupsByContractVersionQuery,
} = api;
