import { endOfMonth } from 'date-fns/endOfMonth';
import { format } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';
import { startOfMonth } from 'date-fns/startOfMonth';

import type {
  DropdownOption,
  PeriodHeader,
  PeriodResponse,
} from 'shared/lib/types';

function compareUTCDates(start: Date, end: Date, ascending: boolean): boolean {
  // only compare month and year (with offsetting the day of the month to
  // NOT the first of the month to eliminate timezone discrepancies),
  // so if the contract assumptions says 12-15 start date, we still include December
  const startYearMonth = new Date(
    Date.UTC(start.getUTCFullYear(), start.getUTCMonth(), 7),
  );
  const endYearMonth = new Date(
    Date.UTC(end.getUTCFullYear(), end.getUTCMonth(), 7),
  );
  return ascending
    ? startYearMonth >= endYearMonth
    : startYearMonth <= endYearMonth;
}

export function extractFileName(fileUrl: string): string {
  const fileName = fileUrl.split('/').pop();
  return fileName ?? '';
}

export const formatCustomDate = (date: string, customFormat: string): string =>
  format(parseISO(date), customFormat);
export const formatFullDate = (date: string): string =>
  format(parseISO(date), 'LLLL d, yyyy');
export const formatShortMonthYear = (date: string): string =>
  format(parseISO(date), 'LLL yyyy');
export const formatLowerShortMonthYear = (date: string): string =>
  format(parseISO(date), 'MMM-yyyy').toLocaleLowerCase();
export const formatShortMonth = (date: string): string =>
  format(parseISO(date), 'LLL');
export const formatShortYear = (date: string): string =>
  format(parseISO(date), 'yyyy');
export const formatFullMonthYear = (date: string): string =>
  format(parseISO(date), 'LLLL yyyy');
export const formatMonthDayYear = (date: string): string =>
  format(parseISO(date), 'LL/dd/yyyy');
export const formatWordMonthDayYear = (date: string | undefined): string =>
  date ? format(parseISO(date), 'MMMM d, yyyy') : '';

/**
 * Please use `date-fns/format(parseISO(date))` instead. If you have a particular format that comes up often,
 * feel free to write a helper function such as `formatFullDate()` above.
 * @deprecated
 */
export function formatDate(
  date: Date | string,
  formatString?: 'mm/dd/yyyy' | 'Month dd, yyyy',
): string {
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  if (formatString === 'mm/dd/yyyy') {
    const month = dateObj.getUTCMonth() + 1;
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();
    return `${month}/${day}/${year}`;
  }
  const monthName = dateObj.toLocaleString('default', { month: 'long' });
  const day = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();
  return `${monthName} ${day}, ${year}`;
}

export function humanize(str: string) {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_\s]+/g, ' ')
    .replace(/^[a-z]/, (firstLetter) => firstLetter.toUpperCase());
}

export function getPeriodHeaderNames(
  start: Date,
  end: Date,
  ascending = true,
  dateFieldFormatter: (isoDateString: string) => string = formatShortMonthYear,
): PeriodHeader[] {
  const months = [];

  // as we only care about the month and year for comparison sake offset the day of the month
  // to NOT the first of the month to eliminate timezone discrepancies
  const dateIterator = new Date(
    Date.UTC(start.getUTCFullYear(), start.getUTCMonth(), 7),
  );
  while (compareUTCDates(dateIterator, end, ascending)) {
    months.push({
      label: formatShortMonthYear(dateIterator.toISOString()),
      field: dateFieldFormatter(dateIterator.toISOString()),
      endDate: format(endOfMonth(dateIterator), 'yyyy-MM-dd'),
    });

    dateIterator.setUTCMonth(dateIterator.getUTCMonth() - (ascending ? 1 : -1));
  }

  return months;
}

export function humanizeCostCategory(costCategory?: string) {
  if (!costCategory) {
    return '';
  }

  switch (costCategory) {
    case 'DIRECT_FEES':
      return 'Direct Fees';
    case 'DIRECT_FEES (amendment-in-progress)':
      return 'Direct Fees (amendment-in-progress)';
    case 'PASS_THROUGH':
      return 'Pass-throughs';
    case 'PASS_THROUGH (amendment-in-progress)':
      return 'Pass-throughs (amendment-in-progress)';
    case 'INVESTIGATOR_FEES':
      return 'Investigator Fees';
    case 'OCC':
      return 'Other Clinical Contracts';
    default:
      return humanize(costCategory);
  }
}

/**
 * @deprecated
 * This is used in the closed periods, but we don't use it anymore.
 */
export const humanizeContractVersionOld = (
  backendVersion: string | undefined,
) => {
  switch (backendVersion) {
    case 'AMENDMENT':
      return 'Amendment';
    case 'LOI':
      return 'LOI';
    case 'OG_WORK_ORDER':
    case 'OG WORK ORDER':
      return 'Original Work Order';
    default:
      return backendVersion;
  }
};

export const humanizeContractVersion = (
  backendVersion: string | undefined,
  amendmentNumber: string | null | undefined,
) => {
  switch (backendVersion) {
    case 'AMENDMENT':
      return `Amendment${amendmentNumber == null ? '' : ` #${amendmentNumber}`}`;
    case 'LOI':
      return 'LOI';
    case 'OG_WORK_ORDER':
    case 'OG WORK ORDER':
      return 'Original Work Order';
    default:
      return backendVersion;
  }
};

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// assumes numbers passed are sorted
export const extractRangesFromArrayOfNumbers = (numbers: number[]) => {
  let result = '';
  if (numbers.length === 0) {
    return result;
  }
  let start = numbers[0];
  let end = numbers[0];
  for (let i = 1; i < numbers.length; i++) {
    if (numbers[i] === end + 1) {
      end = numbers[i];
    } else {
      if (start === end) {
        result += `${start}, `;
      } else {
        result += `${start}-${end}, `;
      }
      start = numbers[i];
      end = numbers[i];
    }

    if (i === numbers.length - 1) {
      if (start === end) {
        result += start.toString();
      } else {
        result += `${start}-${end}`;
      }
    }
  }
  if (result.length === 0) {
    result = start.toString();
  }
  return result;
};

export const differenceInMonthsFractional = (
  startDate: Date,
  endDate: Date,
) => {
  let months =
    (endDate.getUTCFullYear() - startDate.getUTCFullYear()) * 12 +
    (endDate.getUTCMonth() - startDate.getUTCMonth());
  const days = endDate.getUTCDate() - startDate.getUTCDate() + 1; // +1 because we want to include the start date
  const daysInMonth = new Date(
    endDate.getUTCFullYear(),
    endDate.getUTCMonth() + 1,
    0,
  ).getUTCDate();
  months += days / daysInMonth;
  return Math.round(months * 100) / 100;
};

export const navigateUrlUp = (url: string) =>
  url.substring(0, url.lastIndexOf('/'));

// eslint-disable-next-line etc/no-misused-generics -- specifically defining the blob type
export const getJSONFromFile = async <T>(
  path: string,
): Promise<T | undefined> => {
  try {
    const response = await fetch(path);
    return response.json() as unknown as T;
  } catch {
    // this is HIGHLY likely to be poor internet, so we don't want to throw an error
    return undefined;
  }
};

// eslint-disable-next-line import/no-unused-modules -- this will likely be used again
export function timeAgo(date: string) {
  // Adapted from https://stackoverflow.com/a/69122877
  const ranges = {
    years: 3600 * 24 * 365,
    months: 3600 * 24 * 30,
    weeks: 3600 * 24 * 7,
    days: 3600 * 24,
    hours: 3600,
    minutes: 60,
    seconds: 1,
  };
  const secondsElapsed = (new Date(date).getTime() - Date.now()) / 1000;
  for (const entry of Object.entries(ranges)) {
    const value = entry[1];
    if (value < Math.abs(secondsElapsed)) {
      const delta = secondsElapsed / value;
      return new Intl.RelativeTimeFormat('en').format(
        Math.round(delta),
        entry[0] as Intl.RelativeTimeFormatUnit,
      );
    }
  }
}

export function getLastQuarterEquivalentPeriod(
  currentPeriod?: PeriodResponse | null,
  allPeriods?: PeriodResponse[],
) {
  let lastQuarterPeriod;
  if (currentPeriod?.end_date) {
    const currentPeriodEndDate = parseISO(currentPeriod.end_date);

    // as the month ends are different per month (with February being the worst), go to the start of the month
    // and subtract 3 months, else from end of month it won't "always" work due to 31 -> 30 -> 29 -> 28 issues
    const monthEndToLookFor = endOfMonth(
      startOfMonth(currentPeriodEndDate).setMonth(
        currentPeriodEndDate.getMonth() - 3,
      ),
    );

    lastQuarterPeriod = allPeriods?.find((period) => {
      const endDate = parseISO(period.end_date);
      return (
        endDate.getMonth() === monthEndToLookFor.getMonth() &&
        endDate.getFullYear() === monthEndToLookFor.getFullYear()
      );
    });
  }

  return lastQuarterPeriod;
}

export function getDropdownOptionsFromArray<T extends string>(
  array: Array<T | null>,
): Array<DropdownOption<T>> {
  return array
    .filter((item) => item !== null)
    .map((item) => ({ value: item!, label: item ?? '' }));
}

export const CRO_CONTRACT_TABS = {
  CURRENT_CONTRACT: 'current-contract',
  AMENDMENT_IN_PROGRESS: 'amendment-in-progress',
  OVERVIEW: 'overview',
  DIRECT_FEES: 'direct-fees',
  PASS_THROUGHS: 'pass-throughs',
  INVESTIGATOR_FEES: 'investigator-fees',
};

export function truncateString(str: string, maxLength = 35) {
  if (str.length <= maxLength) {
    return str;
  }
  return `${str.substring(0, maxLength)}...`;
}
