import type {
  ForecastFolderRequest,
  ForecastFolderResponse,
} from 'shared/lib/types';

import constructApi from './constructApi';
import { FORECASTS_TAG, FORECAST_MENU_ITEMS_TAG } from './forecasts';

const FORECAST_FOLDERS_TAG = 'FORECAST_FOLDERS';

const apiEndpointFactory = constructApi<ForecastFolderResponse>(
  'forecastfolders',
).withTags(FORECAST_FOLDERS_TAG, [FORECAST_MENU_ITEMS_TAG, FORECASTS_TAG]);
const api = apiEndpointFactory.inject(() => ({
  getForecastFoldersByTrial: apiEndpointFactory.getBy('trial'),
  createForecastFolder: apiEndpointFactory.create<ForecastFolderRequest>([
    FORECAST_MENU_ITEMS_TAG,
  ]),
  updateForecastFolder: apiEndpointFactory.update([FORECAST_MENU_ITEMS_TAG]),
  deleteForecastFolder: apiEndpointFactory.delete([
    FORECASTS_TAG,
    FORECAST_MENU_ITEMS_TAG,
  ]),
}));

export const {
  useGetForecastFoldersByTrialQuery,
  useCreateForecastFolderMutation,
  useUpdateForecastFolderMutation,
  useDeleteForecastFolderMutation,
} = api;
