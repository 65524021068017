import { type ReactElement, useMemo, useRef, useState } from 'react';

import type { AgGridRef } from 'shared/components/ag-grid/CondorAgGrid';

import useCohortAndPeriodWithVersions from 'accruals/pages/clinical-expenses/shared/hooks/useCohortAndPeriodWithVersions';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';

import type { ApiJSON } from 'shared/api/apiJSON';

import PatientJourneyHeader from './components/PatientJourneyHeader';
import PatientJourneySubtabs from './components/PatientJourneySubtabs';
import PatientJourneyGrid from './grids/PatientJourneyGrid';

function PatientJourneyPage(): ReactElement {
  const { periodSpecificPatientCohortTraceId: patientCohortId } =
    useCohortAndPeriodWithVersions();
  const [showExpectedVisits, setShowExpectedVisits] = useState<boolean>(false);
  const gridRef = useRef<AgGridRef<ApiJSON>>(null);
  return (
    <FlexGridContainer>
      <PatientJourneyHeader
        gridRef={gridRef.current}
        setShowExpectedVisits={setShowExpectedVisits}
      />
      <PatientJourneySubtabs />
      <PatientJourneyGrid
        ref={gridRef}
        overlayNoRowsTemplate="No data available"
        parentMenuItem={patientCohortId}
        showExpectedVisits={showExpectedVisits}
        sx={useMemo(() => ({ height: '100%' }), [])}
      />
    </FlexGridContainer>
  );
}

export default PatientJourneyPage;
