import type { ICellRendererParams } from '@ag-grid-community/core';
import DownloadIcon from '@mui/icons-material/DownloadOutlined';
import Box from '@mui/material/Box';

function AgGridFileDownloadCellRenderer(params: ICellRendererParams) {
  const { value } = params;

  if (!value) {
    return null;
  }

  // browsers only download cross-domain if the headers are set right... as we don't control S3, we have to do it a little differently
  const handleDownload = () => {
    void (async () => {
      // concept taken from https://stackoverflow.com/a/77228153
      let url: string | null = null;
      try {
        const response = await fetch(value);
        if (!response.ok) {
          return; // this needs better error handling, but should "in theory" be impossible
        }
        const blob = await response.blob();
        url = URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.setAttribute('download', 'test.json');
        anchor.target = '_blank';
        anchor.style.display = 'none';
        anchor.download = 'download';
        document.body.appendChild(anchor);

        anchor.click();
        document.body.removeChild(anchor);
      } catch {
        return; // this needs better error handling, but should "in theory" be impossible
      } finally {
        if (url != null) {
          URL.revokeObjectURL(url);
        }
      }
    })();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}
      onClick={handleDownload}
    >
      <DownloadIcon color="action" />
    </Box>
  );
}

export default AgGridFileDownloadCellRenderer;
