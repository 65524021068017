import { type ReactElement, useState } from 'react';

import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import useInvoiceListingIntegrationGridColDefs from 'shared/components/integrations/hooks/useInvoiceListingIntegrationGridColDefs';
import IntegrationImportWizard from 'shared/components/integrations/wizards/IntegrationImportWizard';
import Button from 'shared/ui/button/Button';

import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import useIntegration from 'shared/hooks/useIntegration';
import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';
import HasPermission from 'shared/lib/permissions/HasPermission';
import { IntegrationType } from 'shared/lib/types';
import InvoiceUploadWizard from 'shared/wizards/invoice-upload-wizard/InvoiceUploadWizard';

function InvoiceListingHeader(): ReactElement {
  const isIntegrationEnabledForInvoiceListing = useFeatureFlag(
    'integration_invoice_listing',
  );

  const integration = useIntegration(IntegrationType.INVOICE_SNAPSHOT);

  const [showDialog, setShowDialog] = useState(false);
  const [showIntegrationWizard, setShowIntegrationWizard] =
    useState<boolean>(false);

  const invoiceListingColDefs = useInvoiceListingIntegrationGridColDefs();

  function onUploadInvoice() {
    setShowDialog(false);
  }

  return (
    <HasPermission permissions={['canEditCompanyLevelInfo']}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 1,
          mb: 2,
          flexGrow: 0,
          flexShrink: 0,
        }}
      >
        {isIntegrationEnabledForInvoiceListing && integration !== undefined && (
          <Button
            startIcon={<ElectricalServicesIcon />}
            sx={{ alignSelf: 'flex-end' }}
            testId="btn_import_invoice_listing"
            variant="contained"
            onClick={() => setShowIntegrationWizard(true)}
          >
            Import
          </Button>
        )}
        <Button
          startIcon={<FileUploadOutlinedIcon />}
          sx={{ alignSelf: 'flex-end' }}
          testId="upload_invoice_listing"
          variant="contained"
          onClick={() => setShowDialog(true)}
        >
          Upload invoice listing
        </Button>
        {showDialog && <InvoiceUploadWizard onClose={onUploadInvoice} />}
        {showIntegrationWizard && integration !== undefined && (
          <IntegrationImportWizard
            childTotalValidationKey="invoice_amount"
            colDefs={invoiceListingColDefs}
            fieldsToValidate={['trial_id', 'invoice_amount']}
            integration={integration}
            overlayNoRowsTemplate="No invoices."
            setShowIntegration={setShowIntegrationWizard}
            title="Import Invoice Listing"
            headerText={
              <>
                <Typography
                  color={(theme) => theme.palette.primary.main}
                  variant="h6"
                >
                  Review the trials we have associated to each Invoice #.
                  Reassign, split, or ignore lines as needed.
                </Typography>
                <Typography variant="body1">
                  In some cases we were not able to identify which trial the
                  line item should belong to, or the same Invoice# is used in
                  multiple trials. You may reassign line items or split line
                  items across multiple trials if needed. Ignored line items
                  will not be used in any trial.
                </Typography>
              </>
            }
          />
        )}
      </Box>
    </HasPermission>
  );
}

export default withPeriodSpecificGenericWrapper(InvoiceListingHeader);
