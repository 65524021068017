import { useMemo } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';

import useClosedPeriodInvestigatorFeesMonthlyValues from 'forecasting/hooks/useClosedPeriodInvestigatorFeesMonthlyValues';
import {
  type InvestigatorFeesMonthlyValues,
  PeriodMenuItemType,
  type PeriodVersionResponse,
} from 'shared/lib/types';

import { useGetCurrentContractVersionQuery } from 'shared/api/rtkq/contractcontainers';
import { useGetContractInvestigatorFeesGridQuery } from 'shared/api/rtkq/contracts';
import { useGetPeriodMenuItemQuery } from 'shared/api/rtkq/periodmenuitems';

import useForecast from '../hooks/useForecast';
import useForecastedCroExpenseGridOptions from '../hooks/useForecastedCroExpenseGridOptions';
import useForecastedInvestigatorFeesColumnDefs from '../hooks/useForecastedInvestigatorFeesColumnDefs';
import useForecastedInvestigatorFeesRows from '../hooks/useForecastedInvestigatorFeesRows';
import type {
  GenericGridProps,
  PeriodSpecificProps,
} from './ForecastedExpenseGridProps';

function OpenPeriodForecastedInvestigatorFeesExpenseGrid(
  props: PeriodSpecificProps,
) {
  return (
    <GenericForecastedInvestigatorFeesExpenseGrid
      baseColDefs={undefined}
      closedMonthlyValues={undefined}
      processedExpenseData={[]}
      {...props}
    />
  );
}

function ClosedPeriodForecastedInvestigatorFeesExpenseGrid(
  props: PeriodSpecificProps & {
    periodVersion: PeriodVersionResponse | undefined;
  },
) {
  const { contractContainerSlug, periodVersion } = props;
  const { data: parentMenuItems } = useGetPeriodMenuItemQuery(
    periodVersion
      ? {
          period_version: periodVersion.trace_id,
          saved_object_type: PeriodMenuItemType.CRO_CONTRACT_CONTAINER,
          live_slug: contractContainerSlug,
          parent_menu_item: undefined,
        }
      : skipToken,
  );

  const monthlyValues = useClosedPeriodInvestigatorFeesMonthlyValues(
    parentMenuItems?.[0].trace_id,
    periodVersion,
  );

  return (
    <GenericForecastedInvestigatorFeesExpenseGrid
      {...props}
      baseColDefs={undefined}
      closedMonthlyValues={monthlyValues}
      processedExpenseData={[]}
    />
  );
}

function GenericForecastedInvestigatorFeesExpenseGrid(
  props: GenericGridProps & {
    closedMonthlyValues: InvestigatorFeesMonthlyValues | undefined;
  },
) {
  const { sx, showActuals, contractContainerSlug, closedMonthlyValues } = props;

  const { generatedForecast, period } = useForecast();
  const { data: currentContract } = useGetCurrentContractVersionQuery(
    period
      ? {
          trace_id: contractContainerSlug,
          otherParameter: period.trace_id,
        }
      : skipToken,
  );

  const { currentData: expenseGridResponse } =
    useGetContractInvestigatorFeesGridQuery(
      currentContract && period
        ? {
            trace_id: currentContract.trace_id,
            otherParameter: period.trace_id,
          }
        : skipToken,
    );

  const columnDefs = useForecastedInvestigatorFeesColumnDefs(
    generatedForecast,
    showActuals,
  );

  let forecastedMonths: string[] = [];
  if (generatedForecast) {
    forecastedMonths = Object.keys(generatedForecast.expenses[0]).filter(
      (key) => key.startsWith('forecasted_month_'),
    );
  }

  const gridOptions = useGridOptions(useForecastedCroExpenseGridOptions, [
    contractContainerSlug,
  ]);

  const rowData = useForecastedInvestigatorFeesRows(
    generatedForecast,
    contractContainerSlug,
    forecastedMonths,
    closedMonthlyValues ?? expenseGridResponse?.monthly_values,
  );

  const userDisplayOptions = useMemo(
    () => ({ currencyViewMode: 'trial' as const }),
    [],
  );

  return (
    <CondorAgGrid
      columnDefs={columnDefs}
      gridOptions={gridOptions}
      rowData={rowData}
      sx={sx}
      userDisplayOptions={userDisplayOptions}
    />
  );
}

export default function ForecastedInvestigatorFeesExpenseGrid(
  props: PeriodSpecificProps,
) {
  const { period, latestPeriodVersion } = useForecast();

  return period?.is_closed ? (
    <ClosedPeriodForecastedInvestigatorFeesExpenseGrid
      {...props}
      periodVersion={latestPeriodVersion}
    />
  ) : (
    <OpenPeriodForecastedInvestigatorFeesExpenseGrid {...props} />
  );
}
