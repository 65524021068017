import { useMemo } from 'react';

import {
  getPercentCellConfig,
  makeEditableIf,
} from 'shared/components/ag-grid-cells/config';

import usePatientCohorts from 'accruals/wizards/visit-schedules/hooks/usePatientCohorts';
import type { TraceId } from 'shared/lib/types';

function useAssociatedVisitsColumnDefs(cohortId: TraceId) {
  const { patientCohorts } = usePatientCohorts(false);
  const visits = patientCohorts.find(
    (cohort) => cohort.traceId === cohortId,
  )?.patientAssessments;

  return useMemo(
    () => [
      {
        headerName: 'Procedures',
        width: 300,
        children: [
          {
            headerName: 'Procedure',
            field: 'procedure_name',
            suppressHeaderMenuButton: true,
          },
        ],
      },
      ...(visits ?? []).map((visit) => ({
        headerName: visit.name,
        children: [
          {
            field: `part_of_visit_${visit.traceId}`,
            headerName: 'Part of visit?',
            width: 100,
            cellRenderer: 'AgGridSetProcedurePartOfVisitCheckboxCellRenderer',
            cellEditor: 'AgGridCheckboxCellEditor',
            suppressHeaderMenuButton: true,
          },
          {
            field: `occurrence_${visit.traceId}`,
            headerName: '% occurrence',
            width: 100,
            suppressHeaderMenuButton: true,
            ...getPercentCellConfig({ useEmDash: false }),
            ...makeEditableIf(
              ({ data }) => data?.[`part_of_visit_${visit.traceId}`] === true,
            ),
          },
        ],
      })),
    ],
    [visits],
  );
}

export default useAssociatedVisitsColumnDefs;
