import type { EditableCallbackParams } from '@ag-grid-community/core';

import {
  getNumberCellConfig,
  getTrialMoneyCellConfig,
} from 'shared/components/ag-grid-cells/config';

export const forecastMonthColDefs = (
  forecastedMonths: string[],
  isMoney: boolean,
  editable: boolean | ((params: EditableCallbackParams) => boolean) = false,
) =>
  forecastedMonths
    .filter((key) => key !== 'forecasted_month_total')
    .map((key) => {
      const monthName = key.replace('forecasted_month_', '');
      return {
        field: key,
        headerName: monthName.charAt(0).toUpperCase() + monthName.slice(1),
        ...(isMoney ? getTrialMoneyCellConfig() : getNumberCellConfig()),
        aggFunc: 'sum' as const,
        editable,
      };
    });

export const actualMonthColDefs = (
  actualMonths: string[],
  isMoney: boolean,
  hide?: boolean,
) =>
  actualMonths.map((month) => ({
    field: `actual_month_${month}`,
    headerName: month.charAt(0).toUpperCase() + month.slice(1),
    ...(isMoney ? getTrialMoneyCellConfig() : getNumberCellConfig()),
    aggFunc: 'sum' as const,
    hide: hide ?? false,
  }));
