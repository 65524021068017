import { useEffect, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import { getJSONFromFile } from 'shared/helpers/helpers';
import type {
  InvestigatorFeesMonthlyValues,
  PeriodVersionResponse,
} from 'shared/lib/types';
import { PeriodRecordBlobType } from 'shared/lib/types';

import { useGetPeriodRecordBlobQuery } from 'shared/api/rtkq/periodrecordblobs';

export default function useClosedPeriodInvestigatorFeesMonthlyValues(
  parentMenuItem: string | undefined,
  periodVersion: PeriodVersionResponse | undefined,
): InvestigatorFeesMonthlyValues | undefined {
  const [monthlyValues, setMonthlyValues] =
    useState<InvestigatorFeesMonthlyValues>();

  const { currentData: recordBlobs } = useGetPeriodRecordBlobQuery(
    periodVersion && parentMenuItem !== undefined
      ? {
          saved_object_type:
            PeriodRecordBlobType.CRO_INVESTIGATOR_FEES_MONTHLY_VALUES,
          period_version: periodVersion.trace_id,
          parent_menu_item: parentMenuItem,
        }
      : skipToken,
  );

  useEffect(() => {
    void (async () => {
      if (!recordBlobs?.[0]) return;
      setMonthlyValues(await getJSONFromFile(recordBlobs[0].blob));
    })();
  }, [recordBlobs]);
  return monthlyValues;
}
