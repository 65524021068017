import { useEffect, useMemo, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import { skipToken } from '@reduxjs/toolkit/query';
import * as Sentry from '@sentry/react';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';
import type {
  CondorColDef,
  CondorColGroupDef,
} from 'shared/components/ag-grid/types';

import { getJSONFromFile } from 'shared/helpers/helpers';
import { processExpenseRows } from 'shared/hook-helpers/processExpenseRowData';
import processOccExpenseGridColumnDefs from 'shared/hook-helpers/processOccExpenseGridColumnDefs';
import type { PeriodGridBlobResponse } from 'shared/lib/types';
import { PeriodGridBlobType } from 'shared/lib/types';

import type { ApiJSON } from 'shared/api/apiJSON';
import { useGetPeriodGridBlobQuery } from 'shared/api/rtkq/periodgridblobs';
import { useGetOccExpenseGridQuery } from 'shared/api/rtkq/periods';

import useForecast from '../hooks/useForecast';
import useForecastedExpenseGridColumnDefs from '../hooks/useForecastedExpenseGridColumnDefs';
import useForecastedExpenseRowData from '../hooks/useForecastedExpenseRowData';
import useForecastedOccExpenseGridOptions from '../hooks/useForecastedOccExpenseGridOptions';
import type {
  GenericGridProps,
  PeriodSpecificProps,
} from './ForecastedExpenseGridProps';

type OccPeriodSpecificProps = Omit<
  PeriodSpecificProps,
  'contractContainerSlug'
>;

type OccGenericGridProps = Omit<GenericGridProps, 'contractContainerSlug'>;
function OpenPeriodForecastedOccExpenseGrid(props: OccPeriodSpecificProps) {
  const { sx, showActuals } = props;

  const { period } = useForecast();

  const { currentData: expenseData } = useGetOccExpenseGridQuery(
    period?.trace_id ?? skipToken,
  );
  const themeMode = useTheme().palette.mode;

  const columnDefs = useMemo(
    () =>
      processOccExpenseGridColumnDefs({
        isOpenPeriod: !period?.is_closed,
        expenseData,
        commentCountsByRowId: {},
        themeMode,
        canEditTrialInfo: false,
        isGridLocked: true,
        showCommentsButton: false,
      }),
    [period, expenseData, themeMode],
  );
  const rowData = useMemo(() => processExpenseRows(expenseData), [expenseData]);

  return (
    <GenericForecastedOccExpenseGrid
      baseColDefs={columnDefs}
      processedExpenseData={rowData}
      showActuals={showActuals}
      sx={sx}
    />
  );
}

function ClosedPeriodForecastedOccExpenseGrid(props: OccPeriodSpecificProps) {
  const { sx, showActuals } = props;
  const [columnDefs, setColumnDefs] =
    useState<Array<CondorColDef | CondorColGroupDef>>();
  const [rowData, setRowData] = useState<ApiJSON[] | undefined>();

  const { latestPeriodVersion } = useForecast();

  const { currentData: gridSnapshots } = useGetPeriodGridBlobQuery({
    saved_object_type: PeriodGridBlobType.OCC_EXPENSE_GRID,
    period_version: latestPeriodVersion?.trace_id,
  });

  let gridSnapshot: PeriodGridBlobResponse | undefined;
  if (gridSnapshots) {
    gridSnapshot = gridSnapshots[0];

    if (gridSnapshots.length > 1) {
      Sentry.captureMessage(
        "There should only ever be one grid snapshot, but we're getting more than one. This likely means something is missing a parentMenuItem",
      );
    }
  }

  useEffect(() => {
    void (async () => {
      if (gridSnapshot === undefined) {
        return;
      }
      const colDefsFromJson = await getJSONFromFile<CondorColDef[]>(
        gridSnapshot.col_defs,
      );
      if (colDefsFromJson) {
        for (let i = 0; i < colDefsFromJson.length; i++) {
          if (colDefsFromJson[i].cellRenderer === 'AgGridAddCommentRenderer') {
            colDefsFromJson.splice(i, 1);
            break;
          }
        }
      }

      setColumnDefs(colDefsFromJson);
      setRowData(await getJSONFromFile(gridSnapshot.rows));
    })();
  }, [gridSnapshot]);

  return (
    <GenericForecastedOccExpenseGrid
      baseColDefs={columnDefs}
      processedExpenseData={rowData}
      showActuals={showActuals}
      sx={sx}
    />
  );
}

function GenericForecastedOccExpenseGrid(props: OccGenericGridProps) {
  const { sx, baseColDefs, processedExpenseData, showActuals } = props;

  const { generatedForecast } = useForecast();
  const columnDefs = useGridColDefs(
    useForecastedExpenseGridColumnDefs,
    [baseColDefs, generatedForecast, showActuals, null, 'occ'],
    true,
  );

  const gridOptions = useGridOptions(useForecastedOccExpenseGridOptions);

  const occLineItems = useMemo(
    () =>
      Object.values(generatedForecast?.line_items_forecasting ?? {}).reduce(
        (acc, lineItems) => Object.assign(acc, lineItems?.occ_line_items ?? {}),
        {},
      ),
    [generatedForecast],
  );

  const rowData = useForecastedExpenseRowData(
    processedExpenseData,
    generatedForecast,
    occLineItems,
  );

  const userDisplayOptions = useMemo(
    () => ({ currencyViewMode: 'trial' as const }),
    [],
  );

  return (
    <CondorAgGrid
      columnDefs={columnDefs}
      gridOptions={gridOptions}
      rowData={rowData}
      sx={sx}
      userDisplayOptions={userDisplayOptions}
    />
  );
}

export default function ForecastedOccExpenseGrid(
  props: OccPeriodSpecificProps,
) {
  const { period } = useForecast();

  return period?.is_closed ? (
    <ClosedPeriodForecastedOccExpenseGrid {...props} />
  ) : (
    <OpenPeriodForecastedOccExpenseGrid {...props} />
  );
}
