import type {
  DefaultParameterResponse,
  ForecastParameterResponse,
  NonTimelineParameterType,
} from 'shared/lib/types';

import { isDefaultLabeledParameterResponse } from 'shared/api/rtkq/forecasts';

export function processForecastParameters(
  defaultParameters: DefaultParameterResponse | undefined,
  forecastParameters: ForecastParameterResponse[] | undefined,
  assumptionPrefix: string,
  parameterType: NonTimelineParameterType,
) {
  const actualParameters = defaultParameters?.[parameterType];
  const matchingForecastParameters = forecastParameters?.filter(
    (parameter) => parameter.type === parameterType,
  );
  const actualsTotal = Object.values(actualParameters ?? {}).reduce(
    (acc, value) => {
      if (isDefaultLabeledParameterResponse(value)) {
        return acc + value.value;
      }
      return acc;
    },
    0,
  );

  const forecastedTotal = Object.values(
    matchingForecastParameters ?? {},
  ).reduce((acc, value) => acc + Number(value.value), 0);

  const rows = Object.entries(actualParameters ?? {}).map(([region, value]) => {
    const result = {
      assumption: `${assumptionPrefix} ${region}`,
      isTotal: false,
      forecast: 0,
      actual: 0,
    };

    const forecastValue = forecastParameters?.find(
      (parameter) => parameter.region_name === region,
    );

    if (isDefaultLabeledParameterResponse(value)) {
      Object.assign(result, {
        actual: value.value,
        forecast: forecastValue?.value ?? 0,
        iconTooltipMessage: `Based off ${value.vendor_type} ${value.vendor_name}`,
        parameter_trace_id: forecastValue?.trace_id,
        region_name: region,
        parameterType,
      });
    }

    return result;
  });

  rows.push({
    assumption: `${assumptionPrefix} global total`,
    actual: actualsTotal,
    forecast: forecastedTotal,
    isTotal: true,
  });

  return rows;
}
