import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import {
  getSelectCellConfig,
  makeEditableIf,
} from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import { selectForecast } from 'forecasting/state/slices/forecastSlice';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';

import { useGenerateForecastQuery } from 'shared/api/rtkq/forecasts';

import {
  actualMonthColDefs,
  forecastMonthColDefs,
} from './forecastMonthColDefs';

function usePatientSiteActivityColDefs(
  _isOpenPeriod: boolean,
  type: 'PATIENT' | 'SITE',
  showActuals: boolean,
): CondorColDef[] {
  const forecast = useSelector(selectForecast);
  const manualEditingIsEnabled = useFeatureFlag(
    `forecasting_parameters_manual_${type.toLowerCase()}_activity`,
  );
  const { currentData: forecastGrids } = useGenerateForecastQuery(
    forecast.trace_id,
  );
  const forecastTypeMappings = {
    RTE: 'Per site per month',
    MTV: 'Manual',
  };

  return useMemo(() => {
    const forecastedMonths = Object.keys(
      forecastGrids?.patient_activity_grid[0] ?? {},
    ).filter((key) => key.startsWith('forecasted_month_'));
    return [
      {
        headerName: type === 'PATIENT' ? 'Enrolled patients' : 'Active sites',
        children: [
          {
            field: 'region_name',
            width: 150,
            headerName: 'Region',
            aggFunc: 'totalLabel',
            pinned: 'left',
          },
          {
            field: 'actuals_total',
            width: 75,
            headerName: 'LTD total',
            aggFunc: 'sum',
            pinned: 'left',
          },
          {
            field: 'expected_trial_total',
            width: 75,
            headerName: 'Expected trial total',
            aggFunc: 'sum',
            pinned: 'left',
          },
          {
            field: 'input_type',
            width: 150,
            headerName: 'Forecast type',
            refData: forecastTypeMappings,
            editable: true,
            ...getSelectCellConfig(),
            pinned: 'left',
          },
          {
            field: 'per_month',
            width: 75,
            headerName: type === 'PATIENT' ? 'Per site per month' : 'Per month',
            ...makeEditableIf(({ data }) => data?.input_type === 'RTE'),
            pinned: 'left',
          },
          {
            field: 'forecasted_total',
            width: 75,
            headerName: 'Forecasted total',
            aggFunc: 'sum',
            pinned: 'left',
          },
          {
            field: 'grand_total',
            width: 75,
            headerName: 'Grand total',
            aggFunc: 'sum',
            pinned: 'left',
          },
        ],
      },
      {
        headerName: 'Actual',
        children: actualMonthColDefs(
          forecastGrids?.actual_months ?? [],
          false,
          !showActuals,
        ),
      },
      {
        headerName: 'Forecasted',
        children: forecastMonthColDefs(
          forecastedMonths,
          false,
          ({ data }) => manualEditingIsEnabled && data?.input_type === 'MTV',
        ),
      },
    ];
  }, [showActuals, forecastGrids, type]);
}

export default usePatientSiteActivityColDefs;
