import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';

import { selectForecast } from 'forecasting/state/slices/forecastSlice';

import { useGenerateForecastQuery } from 'shared/api/rtkq/forecasts';

function usePatientSiteActivityRows(type: 'PATIENT' | 'SITE') {
  const forecast = useSelector(selectForecast);
  const { currentData: forecastGrids } = useGenerateForecastQuery(
    forecast.trace_id,
  );

  return useMemo(
    () =>
      type === 'PATIENT'
        ? cloneDeep(forecastGrids?.patient_activity_grid)
        : cloneDeep(forecastGrids?.site_activity_grid),
    [type, forecastGrids],
  );
}

export default usePatientSiteActivityRows;
