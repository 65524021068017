import { useMemo } from 'react';

import type { CellClassParams } from '@ag-grid-community/core';

import { makeEditableIf } from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import type { UnprocessedPOResponse } from 'shared/lib/types';
import { IntegrationType } from 'shared/lib/types';

export default function usePOListingIntegrationGridColDefs(): CondorColDef[] {
  return useMemo(() => {
    const ignoredCheck = (data: UnprocessedPOResponse) => !data.ignored;
    const studyIdInvalidCheck = (params: CellClassParams) =>
      (params.data.study_id === '' || params.data.study_id === null) &&
      ignoredCheck(params.data);
    const poAmountInvalidCheck = (params: CellClassParams) =>
      (params.data.po_amount === '' ||
        params.data.po_amount === '0.00' ||
        params.data.po_amount === null ||
        params.data.po_amount === 0) &&
      ignoredCheck(params.data);

    return [
      { field: 'trace_id', hide: true, width: 150 },
      {
        children: [
          {
            field: 'error',
            headerName: '',
            flex: 0,
            width: 10,
            cellRenderer: 'AgGridIntegrationsErrorRenderer',
            suppressMenu: true,
          },
          {
            headerName: 'Ignore',
            field: 'ignored',
            flex: 0,
            width: 75,
            resizable: false,
            cellRenderer: 'AgGridIntegrationsIgnoreRenderer',
          },
          {
            headerName: 'Split',
            field: 'split',
            resizable: false,
            width: 65,
            flex: 0,
            cellRenderer: 'AgGridIntegrationsSplitRenderer',
            cellRendererParams: {
              integrationType: IntegrationType.PURCHASE_ORDER_SNAPSHOT,
            },
          },
        ],
      },
      {
        headerName: 'TRIAL',
        children: [
          {
            headerName: 'Condor Value',
            field: 'study_id',
            width: 150,
            cellRenderer: 'AgGridIntegrationsTrialIdRenderer',
            cellRendererParams: {
              integrationType: IntegrationType.PURCHASE_ORDER_SNAPSHOT,
            },
            wrapText: true,
            autoHeight: true,
            cellClassRules: {
              'ag-cell-error-selected': studyIdInvalidCheck,
            },
            ...makeEditableIf(({ data }) => ignoredCheck(data)),
          },
          {
            headerName: 'Integration Value',
            field: 'external_study_id',
            width: 150,
            editable: false,
          },
        ],
      },
      {
        headerName: 'PO #',
        children: [
          {
            headerName: 'Condor Value',
            field: 'po_number',
            width: 150,
          },
          {
            headerName: 'Integration Value',
            field: 'external_po_number',
            width: 150,
            editable: false,
          },
        ],
      },
      {
        headerName: 'PO AMOUNT',
        children: [
          {
            headerName: 'Condor Value',
            field: 'po_amount',
            valueGetter: 'Number(node.data.po_amount)',
            width: 150,
            cellEditor: 'AgGridTextEditor',
            cellEditorParams: { inputType: 'number' },
            cellRenderer: 'AgGridMoneyCellRenderer',
            cellClassRules: {
              'ag-cell-error-selected': poAmountInvalidCheck,
            },
            ...makeEditableIf(
              // Allow PO Amount editing in two conditions:
              // 1. If it is parent and split is False
              // 2. If it is a child and split is true
              // Since above is a XOR, A !== B is a valid check
              ({ data }) => ignoredCheck(data) && data.isParent !== data.split,
            ),
          },
          {
            headerName: 'Integration value',
            field: 'external_po_amount',
            valueGetter: 'Number(node.data.external_po_amount)',
            width: 150,
            cellRenderer: 'AgGridMoneyCellRenderer',
            editable: false,
          },
        ],
      },
      {
        headerName: 'CURRENCY',
        children: [
          { headerName: 'Condor Value', field: 'currency', width: 150 },
          {
            headerName: 'Integration Value',
            field: 'external_currency',
            width: 150,
            editable: false,
          },
        ],
      },
      {
        headerName: 'Vendor',
        children: [
          {
            headerName: 'Condor Value',
            field: 'vendor',
            aggFunc: 'totalLabel',
            width: 150,
          },
          {
            headerName: 'Integration Value',
            field: 'external_vendor',
            width: 150,
            editable: false,
          },
        ],
      },
      {
        headerName: 'G/L account',
        children: [
          { headerName: 'Condor Value', field: 'gl_account', width: 150 },
          {
            headerName: 'Integration Value',
            field: 'external_gl_account',
            width: 150,
            editable: false,
          },
        ],
      },
      {
        headerName: 'Description',
        children: [
          { headerName: 'Condor Value', field: 'description', width: 150 },
          {
            headerName: 'Integration Value',
            field: 'external_description',
            width: 150,
            editable: false,
          },
        ],
      },
      {
        headerName: 'PO date',
        children: [
          { headerName: 'Condor Value', field: 'date', width: 150 },
          {
            headerName: 'Integration Value',
            field: 'external_date',
            width: 150,
            editable: false,
          },
        ],
      },
      {
        headerName: 'Status',
        children: [
          { headerName: 'Condor Value', field: 'status', width: 150 },
          {
            headerName: 'Integration Value',
            field: 'external_status',
            width: 150,
            editable: false,
          },
        ],
      },
    ];
  }, []);
}
