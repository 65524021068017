import { useMemo } from 'react';

import { useTheme } from '@mui/material/styles';

import type { CondorColDef } from 'shared/components/ag-grid/types';
import useRowCommentsCountLoader from 'shared/components/comments/hooks/useRowCommentsCountLoader';

import processOccExpenseGridColumnDefs from 'shared/hook-helpers/processOccExpenseGridColumnDefs';
import useHasPermission from 'shared/lib/permissions/useHasPermission';
import { CommentLocationType } from 'shared/lib/types';

import type { ApiJSON } from 'shared/api/apiJSON';

export default function useOccExpenseGridColumnDefs(
  isOpenPeriod: boolean,
  expenseData: ApiJSON | undefined,
  isGridLocked: boolean,
): CondorColDef[] {
  const themeMode = useTheme().palette.mode;
  const canEditTrialInfo = useHasPermission(['canEditTrialInfo']);

  const commentCountsByRowId = useRowCommentsCountLoader(
    CommentLocationType.OCC_EXPENSE,
  );

  return useMemo(
    () =>
      processOccExpenseGridColumnDefs({
        isOpenPeriod,
        expenseData,
        commentCountsByRowId,
        themeMode,
        canEditTrialInfo,
        isGridLocked,
      }),
    [
      expenseData,
      isOpenPeriod,
      isGridLocked,
      themeMode,
      canEditTrialInfo,
      commentCountsByRowId,
    ],
  );
}
