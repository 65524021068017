/*
This "page" solely exists to handle Zendesk JWT authentication
https://github.com/zendesk/zendesk_jwt_sso_examples/blob/master/form_submission/react_jwt.jsx
https://support.zendesk.com/hc/en-us/articles/4408845838874-Enabling-JWT-single-sign-on
*/

import { useEffect, useRef } from 'react';

import { useLazyGetZendeskTokenQuery } from 'shared/api/rtkq/users';

function Zendesk() {
  const [getToken] = useLazyGetZendeskTokenQuery();
  // by the time these are used, the component has already been mounted due to being in the useEffect
  const formRef = useRef<HTMLFormElement>(null!);
  const inputRef = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    void (async () => {
      const zendeskToken = await getToken('').unwrap();
      inputRef.current.value = zendeskToken;
      formRef.current.submit();
    })();
  }, [getToken]);

  return (
    <div style={{ display: 'none' }}>
      <form
        ref={formRef}
        action="https://condorsoftware.zendesk.com/access/jwt"
        method="post"
      >
        <input ref={inputRef} name="jwt" type="hidden" />
      </form>
    </div>
  );
}

export default Zendesk;
