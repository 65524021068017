import sortBy from 'lodash/sortBy';

import type {
  BottomLineAdjustment,
  BottomLineAdjustmentResponse,
  ContractCostCategoryInfoRequestParams,
  ContractCostCategoryInfoResponse,
  ContractFile,
  ContractInfo,
  ContractVersionRequestParams,
  ContractVersionResponse,
  ContractWithStatusResponse,
  TraceId,
  VendorType,
} from 'shared/lib/types';
import { CroCostCategories } from 'shared/lib/types';

import { GLOBAL_REGION_GROUP } from './useAccrualsRegionGroups';

export function sortContractCostCategories(
  categories: ContractCostCategoryInfoRequestParams[],
) {
  return sortBy(
    categories,
    (cat) => CroCostCategories.indexOf(cat.category) || -1,
  );
}

export function mapContractInfoToDataRequest(
  contractInfo: ContractInfo,
  contractContainerId: TraceId,
  periodTraceId?: TraceId,
): ContractVersionRequestParams {
  return {
    contract_container: contractContainerId,
    description: contractInfo.description,
    // following two are not a column in the backend model, but required for create
    status_for_period: contractInfo.contractStatus,
    period: periodTraceId,
    po_number: String(contractInfo.poNumber ?? ''),
    cnf_threshold_amount: contractInfo.accountInfo.cnfThresholdAmount,
    cnf_threshold_percentage: contractInfo.accountInfo.cnfThresholdPercentage,
    bonus_and_penalties: contractInfo.accountInfo.bonusesAndPenalties,
    version: contractInfo.contractVersion,
    version_name: contractInfo.versionName,
    effective_date: contractInfo.effectiveDate?.length
      ? contractInfo.effectiveDate
      : null,
    aip_effective_date: contractInfo.aipEffectiveDate?.length
      ? contractInfo.aipEffectiveDate
      : null,
    voided_date: contractInfo.voidedDate?.length
      ? contractInfo.voidedDate
      : null,
    amendment_number: contractInfo.amendmentNumber,
  };
}

export function getBottomLineAdjustmentFromApiResponse(
  adjustment: BottomLineAdjustmentResponse,
): BottomLineAdjustment {
  return {
    traceId: adjustment.trace_id,
    adjustmentType: adjustment.adjustment_type,
    amountType: adjustment.amount_type,
    amount: Number.parseFloat(String(adjustment.amount)),
    amountPercentage: Number.parseFloat(String(adjustment.amount_percentage)),
  };
}

export function getContractInfoFromApiResult(
  contractVersion: ContractVersionResponse | ContractWithStatusResponse,
  bottomLineAdjustments?: BottomLineAdjustment[],
  documents?: ContractFile[],
  contractCostCategoryInfos?: ContractCostCategoryInfoRequestParams[],
): ContractInfo {
  return {
    traceId: contractVersion.trace_id,
    contractStatus: contractVersion.status_for_period ?? 'CURRENT', // the default value should never be actually used, status_for_period should always be set for this function
    contractVersion: contractVersion.version,
    versionName: contractVersion.version_name,
    poNumber: contractVersion.po_number,
    effectiveDate: contractVersion.effective_date,
    aipEffectiveDate: contractVersion.aip_effective_date,
    voidedDate: contractVersion.voided_date,
    amendmentNumber: contractVersion.amendment_number,
    accountInfo: {
      useCnfThreshold: !!(
        contractVersion.cnf_threshold_amount ??
        contractVersion.cnf_threshold_percentage
      ),
      cnfThresholdAmount: contractVersion.cnf_threshold_amount,
      cnfThresholdPercentage: contractVersion.cnf_threshold_percentage,
      bonusesAndPenalties: contractVersion.bonus_and_penalties ?? '',
      bottomLineAdjustments: bottomLineAdjustments ?? [],
      costCategoryInfos: contractCostCategoryInfos,
    },
    documents: documents ?? [],
    trialRegions:
      'trial_regions' in contractVersion ? contractVersion.trial_regions : [],
    regionGroups:
      'region_groups' in contractVersion
        ? [
            GLOBAL_REGION_GROUP,
            ...contractVersion.region_groups.map(
              ({ name, regions, trace_id }) => ({
                name,
                contract_version: contractVersion.trace_id,
                regions,
                trace_id,
                global: false,
                new: false,
              }),
            ),
          ]
        : [],
  };
}

export function getContractCostCategoryInfosFromApiResult(
  contractCostCategoryInfos: ContractCostCategoryInfoResponse[],
  vendorType: VendorType,
): ContractCostCategoryInfoRequestParams[] {
  let newContractCostCategoryInfos:
    | ContractCostCategoryInfoRequestParams[]
    | undefined = contractCostCategoryInfos.map((info) => ({
    category: info.category,
    gl_account: info.gl_account?.trace_id,
    gross_value: Number.parseFloat(String(info.gross_value)),
    trace_id: info.trace_id,
  }));
  // ensure that we have a contract cost category info for each CRO category
  if (vendorType === 'CRO') {
    const existingCategories = newContractCostCategoryInfos.map(
      (info) => info.category,
    );
    const missingCategories = CroCostCategories.filter(
      (category) => !existingCategories.includes(category),
    );
    for (const category of missingCategories) {
      // TODO: There should be a more efficient way to make two arrays and concatenate them
      newContractCostCategoryInfos = [
        ...newContractCostCategoryInfos,
        { category, gross_value: undefined, gl_account: undefined },
      ];
    }
  }

  return sortContractCostCategories(newContractCostCategoryInfos);
}
