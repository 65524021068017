import type {
  BaseQueryFn,
  EndpointBuilder,
  EndpointDefinitions,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';

import type {
  ApiFilters,
  BaseRequestFields,
  BaseResponseFields,
  ExtraRouteApiFiltersWithPath,
  TraceId,
} from 'shared/lib/types';

import type { ExtraOptionsType } from 'shared/api/baseApi';
import baseApi from 'shared/api/baseApi';

export type TagType = string;

// RTKQ doesn't expose these on an API, so we have to keep track of them this way (and this is fine to be global)
let REGISTERED_TAGS = new Set<TagType>();

/* eslint-disable etc/no-misused-generics -- We're explicitly defining an API, so no objects are available to define generic types from */
export default function constructApi<
  ResponseType extends BaseResponseFields,
  RequestType extends BaseRequestFields = ResponseType,
>(path: string) {
  return new ApiFactory<ResponseType, RequestType>(path);
}

export function getRegisteredTags() {
  return [...REGISTERED_TAGS];
}

class ApiFactory<
  ResponseType extends BaseResponseFields,
  RequestType extends BaseRequestFields = ResponseType,
> {
  readonly path: string;

  constructor(path: string) {
    this.path = path;
  }

  withTags(primaryTag: TagType, otherTags: TagType[] = []) {
    REGISTERED_TAGS = new Set([primaryTag, ...otherTags, ...REGISTERED_TAGS]);
    return new ApiEndpointFactory<ResponseType, RequestType>(
      this.path,
      primaryTag,
      otherTags,
    );
  }
}

class ApiEndpointFactory<
  ResponseType extends BaseResponseFields,
  RequestType extends BaseRequestFields = ResponseType,
> {
  readonly path: string;
  readonly primaryTag: TagType;
  private _build!: EndpointBuilder<
    BaseQueryFn<
      FetchArgs | string,
      unknown,
      FetchBaseQueryError,
      object,
      FetchBaseQueryMeta
    >,
    TagType,
    string
  >;
  private readonly _api;
  constructor(path: string, primaryTag: TagType, otherTags: TagType[] = []) {
    this.path = path;
    this.primaryTag = primaryTag;
    this._api = baseApi.enhanceEndpoints({
      addTagTypes: [primaryTag, ...otherTags],
    });
  }

  inject<NewDefinitions extends EndpointDefinitions>(
    endpoints: () => NewDefinitions,
  ) {
    return this._api.injectEndpoints({
      endpoints: (build) => {
        this._build = build;
        return endpoints();
      },
      overrideExisting: true,
    });
  }

  _addOptionalPath(additionalPath?: string) {
    if ((additionalPath ?? '').length > 0) {
      return additionalPath?.endsWith('/')
        ? additionalPath
        : `${additionalPath}/`;
    }

    return '';
  }

  _generateInvalidateTags(
    additionalTags: TagType[] = [],
    includePrimaryTag = true,
  ) {
    const primaryTagArray = includePrimaryTag ? [this.primaryTag] : [];
    return { invalidatesTags: [...primaryTagArray, ...additionalTags] };
  }

  _generateProvidesTags(
    additionalTags: TagType[] = [],
    includePrimaryTag = true,
  ) {
    const primaryTagArray = includePrimaryTag ? [this.primaryTag] : [];
    return { providesTags: [...primaryTagArray, ...additionalTags] };
  }

  _generatePath(pathParts?: Array<string | undefined> | string | void) {
    if (pathParts === undefined) {
      pathParts = '';
    }

    if (!Array.isArray(pathParts)) {
      pathParts = [pathParts];
    }

    return `${this.path}/${pathParts
      .filter((part) => part !== this.path)
      .map((part) => this._addOptionalPath(part))
      .join('')}`;
  }

  /* ****************
      C: Create
  **************** */
  _generateCreateQuery<CreateRequestType = RequestType>(
    additionalPath = '',
    method = 'POST',
  ) {
    return {
      query: (body: CreateRequestType) => ({
        url: `${this.path}/${this._addOptionalPath(additionalPath)}`,
        method,
        body,
      }),
    };
  }

  create<CreateRequestType = RequestType, CreateResponseType = ResponseType>(
    additionalTags: TagType[] = [],
    additionalPath = '',
    includePrimaryTag = true,
  ) {
    return this._build.mutation<CreateResponseType, Partial<CreateRequestType>>(
      {
        ...this._generateCreateQuery(additionalPath),
        ...this._generateInvalidateTags(additionalTags, includePrimaryTag),
      },
    );
  }

  /* ****************
      R: Read
  **************** */
  _generateQuery(queryParts?: string[] | string) {
    if (queryParts === undefined) {
      return '';
    }

    if (!Array.isArray(queryParts)) {
      queryParts = [queryParts];
    }

    return `?${queryParts.filter((str) => str.length > 0).join('&')}`;
  }

  _(filters?: ApiFilters) {
    if (filters === undefined || Object.keys(filters).length === 0) {
      return '';
    }

    return Object.entries(filters)
      .filter((entry) => entry[1] !== undefined)
      .map(
        (entry) =>
          `filter[${entry[0]}]=${Array.isArray(entry[1]) ? entry[1].join(',') : entry[1]}`,
      );
  }

  _generatePage(page?: number) {
    if (page === undefined) {
      return '';
    }

    return `page=${page}`;
  }

  _generateSort(sort?: string[] | string) {
    if (sort === undefined || sort.length === 0) {
      return '';
    }

    if (!Array.isArray(sort)) {
      sort = [sort];
    }

    return `sort=${sort.join(',')}`;
  }

  _generatePathWithFilters(
    filters: ApiFilters,
    sort?: string[] | string,
    path?: string,
  ) {
    const filterParams = this._(filters);
    const sortParams = this._generateSort(sort);

    return `${this._generatePath(path)}${this._generateQuery([...filterParams, sortParams])}`;
  }

  _generatePathWithFiltersAndPaging(
    path: Array<string | undefined> | string,
    filters: ApiFilters,
    page?: number,
    sort?: string[] | string,
  ) {
    const filterParams = this._(filters);
    const sortParams = this._generateSort(sort);
    const pageParams = this._generatePage(page);

    return `${this._generatePath(path)}${this._generateQuery([...filterParams, sortParams, pageParams])}`;
  }

  get(additionalTags: TagType[] = []) {
    return this._build.query<ResponseType, TraceId | void>({
      query: (traceId) => this._generatePath(traceId),
      ...this._generateProvidesTags(additionalTags),
    });
  }
  getAll(additionalTags: TagType[] = []) {
    return this._build.query<ResponseType[], void>({
      query: () => this._generatePath(),
      ...this._generateProvidesTags(additionalTags),
    });
  }
  getExtraRoute<OtherResponseType = ResponseType>(
    pathPart: string,
    additionalTags: TagType[] = [],
    extraOptions?: ExtraOptionsType,
  ) {
    return this._build.query<OtherResponseType, TraceId | undefined>({
      query: (traceId?) => this._generatePath([traceId, pathPart]),
      ...this._generateProvidesTags(additionalTags, false),
      extraOptions,
    });
  }
  getExtraRouteWithFiltersAndPaging<OtherResponseType = ResponseType>(
    pathPart: string,
    additionalTags: TagType[] = [],
    sort?: string[] | string,
  ) {
    return this._build.query<OtherResponseType, ExtraRouteApiFiltersWithPath>({
      query: ({ trace_id, filters, page, sort: querySort }) =>
        this._generatePathWithFiltersAndPaging(
          [trace_id, pathPart],
          filters,
          page,
          querySort ?? sort ?? undefined,
        ),
      ...this._generateProvidesTags(additionalTags),
    });
  }
  getExtraRouteTwoParameters<
    OtherResponseType,
    SecondParameterType extends string = string,
  >(pathPart: string, additionalTags: TagType[] = []) {
    return this._build.query<
      OtherResponseType,
      { trace_id: TraceId; otherParameter: SecondParameterType }
    >({
      query: ({ trace_id, otherParameter }) =>
        this._generatePath([trace_id, pathPart, otherParameter]),
      ...this._generateProvidesTags(additionalTags, false),
    });
  }
  getExtraRouteTwoParametersWithFiltersAndPaging<
    OtherResponseType,
    SecondParameterType extends string = string,
  >(
    pathPart: string,
    additionalTags: TagType[] = [],
    sort?: string[] | string,
  ) {
    return this._build.query<
      OtherResponseType,
      ExtraRouteApiFiltersWithPath & { otherParameter: SecondParameterType }
    >({
      query: ({ trace_id, otherParameter, filters, page, sort: querySort }) =>
        this._generatePathWithFiltersAndPaging(
          [trace_id, pathPart, otherParameter],
          filters,
          page,
          querySort ?? sort ?? undefined,
        ),
      ...this._generateProvidesTags(additionalTags),
    });
  }
  getExtraRouteThreeParameters<
    OtherResponseType,
    SecondParameterType extends string = string,
    ThirdParameterType extends string = string,
  >(pathPart: string, additionalTags: TagType[] = []) {
    return this._build.query<
      OtherResponseType,
      {
        trace_id: TraceId;
        secondParameter: SecondParameterType;
        thirdParameter: ThirdParameterType;
      }
    >({
      query: ({ trace_id, secondParameter, thirdParameter }) =>
        this._generatePath([
          trace_id,
          pathPart,
          secondParameter,
          thirdParameter,
        ]),
      ...this._generateProvidesTags(additionalTags, false),
    });
  }
  getBy(
    filterColumn: string,
    sort?: string[] | string,
    additionalTags: TagType[] = [],
  ) {
    return this._build.query<ResponseType[], string>({
      query: (filterValue) =>
        this._generatePathWithFilters({ [filterColumn]: filterValue }, sort),
      ...this._generateProvidesTags(additionalTags),
    });
  }
  getWithFilters(sort?: string[] | string, additionalTags: TagType[] = []) {
    return this._build.query<ResponseType[], ApiFilters>({
      query: (filters) => this._generatePathWithFilters(filters, sort),
      ...this._generateProvidesTags(additionalTags),
    });
  }

  getPathWithFilters<OtherResponseType>(
    path: string,
    sort?: string[] | string,
    additionalTags: TagType[] = [],
  ) {
    return this._build.query<OtherResponseType, ApiFilters>({
      query: (filters) => this._generatePathWithFilters(filters, sort, path),
      ...this._generateProvidesTags(additionalTags),
    });
  }

  /* ****************
      U: Update
  **************** */
  update<
    UpdateRequestType extends BaseRequestFields = RequestType,
    UpdateResponseType = ResponseType,
  >(
    additionalTags: TagType[] = [],
    additionalPath = '',
    includePrimaryTag = true,
  ) {
    return this._build.mutation<
      UpdateResponseType,
      Partial<UpdateRequestType> & Pick<UpdateRequestType, 'trace_id'>
    >({
      query: ({ trace_id, ...body }: UpdateRequestType) => ({
        url: this._generatePath([
          this.path,
          trace_id,
          this._addOptionalPath(additionalPath),
        ]),
        method: 'PATCH',
        body,
      }),
      ...this._generateInvalidateTags(additionalTags, includePrimaryTag),
    });
  }

  updateTwoParameters<
    UpdateRequestType extends BaseRequestFields = RequestType,
    UpdateResponseType = ResponseType,
    SecondParameterType extends TraceId = TraceId,
  >(additionalTags: TagType[] = [], additionalPath = '') {
    return this._build.mutation<
      UpdateResponseType,
      {
        object: Partial<UpdateRequestType> &
          Pick<UpdateRequestType, 'trace_id'>;
        secondParameter: SecondParameterType;
      }
    >({
      query: ({ object, secondParameter }) => {
        const { trace_id, ...body } = object;
        return {
          url: this._generatePath([
            this.path,
            trace_id,
            this._addOptionalPath(additionalPath),
            secondParameter,
          ]),
          method: 'PATCH',
          body,
        };
      },
      ...this._generateInvalidateTags(additionalTags, false),
    });
  }

  updateThreeParameters<
    UpdateRequestType extends BaseRequestFields = RequestType,
    UpdateResponseType = ResponseType,
    SecondParameterType extends TraceId = TraceId,
    ThirdParameterType extends string = string,
  >(additionalTags: TagType[] = [], additionalPath = '') {
    return this._build.mutation<
      UpdateResponseType,
      {
        object: Partial<UpdateRequestType> &
          Pick<UpdateRequestType, 'trace_id'>;
        secondParameter: SecondParameterType;
        thirdParameter: ThirdParameterType;
      }
    >({
      query: ({ object, secondParameter, thirdParameter }) => {
        const { trace_id, ...body } = object;
        return {
          url: this._generatePath([
            this.path,
            trace_id,
            this._addOptionalPath(additionalPath),
            secondParameter,
            thirdParameter,
          ]),
          method: 'PATCH',
          body,
        };
      },
      ...this._generateInvalidateTags(additionalTags, false),
    });
  }

  /* ****************
      D: Delete
  **************** */
  _generateDeleteQuery(additionalPath = '', method = 'DELETE') {
    return {
      query: (traceId: TraceId) => ({
        url: `${this.path}/${traceId}/${this._addOptionalPath(additionalPath)}`,
        method,
      }),
    };
  }

  delete(additionalTags: TagType[] = [], additionalPath = '') {
    return this._build.mutation<void, TraceId>({
      ...this._generateDeleteQuery(additionalPath),
      ...this._generateInvalidateTags(additionalTags),
    });
  }
}
/* eslint-enable etc/no-misused-generics */
