import type { ChangeEventHandler } from 'react';

import type { SxProps } from '@mui/material/styles';

import type {
  CondorTextFieldProps,
  ErrorsTextFieldProps,
} from 'shared/components/text-field/CondorTextField';
import CondorTextField from 'shared/components/text-field/CondorTextField';

type Props = CondorTextFieldProps &
  ErrorsTextFieldProps & {
    value?: string;
    size?: 'medium' | 'small';
    label?: string;
    required?: boolean;
    variant?: 'filled' | 'outlined' | 'standard';
    disabled?: boolean;
    helperText?: string;
    name?: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    className?: string;
    fullWidth?: boolean;
    sx?: SxProps;
    type?: 'date' | 'month';
  };

function DatePicker(props: Props) {
  const { type = 'date', ...rest } = props;
  return (
    <CondorTextField {...rest} InputLabelProps={{ shrink: true }} type={type} />
  );
}

export default DatePicker;
