import type { ForwardedRef, ReactElement } from 'react';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

import type { SxProps } from '@mui/material/styles';

import type {
  AgGridRef,
  UserDisplayOptions,
} from 'shared/components/ag-grid/CondorAgGrid';
import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';
import useLockedGridStatus from 'shared/components/lock-grid/useLockedGridStatus';

import useCroInvestigatorFeesGridOptions from 'accruals/pages/clinical-expenses/cro/hooks/useCroInvestigatorFeesGridOptions';
import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import { LockGrid, PeriodGridBlobType } from 'shared/lib/types';

import type { ApiJSON } from 'shared/api/apiJSON';

import useContractAndPeriodWithVersions from '../../shared/hooks/useContractAndPeriodWithVersions';
import useCroInvestigatorFeesReconColumnDefs from '../hooks/useCroInvestigatorFeesReconColumnDefs';
import useCroInvestigatorFeesReconRows from '../hooks/useCroInvestigatorFeesReconRows';

type Props = {
  overlayNoRowsTemplate?: string;
  sx?: SxProps;
  userDisplayOptions: UserDisplayOptions;
};

function CroInvestigatorFeesReconGrid(
  props: Props,
  ref: ForwardedRef<AgGridRef<ApiJSON> | undefined>,
): ReactElement {
  const { sx, overlayNoRowsTemplate, userDisplayOptions } = props;

  const { contractVersionTraceId, periodTraceId, loadingContractInformation } =
    useContractAndPeriodWithVersions();

  const isGridLocked = useLockedGridStatus(
    LockGrid.CRO_INVESTIGATOR_FEES_RECONCILIATION_GRID,
    null,
  );
  const columnDefs = useGridColDefs(
    useCroInvestigatorFeesReconColumnDefs,
    [contractVersionTraceId, periodTraceId, isGridLocked],
    isGridLocked,
  );
  const rowData = useCroInvestigatorFeesReconRows(
    contractVersionTraceId,
    periodTraceId,
    loadingContractInformation,
  );
  const gridOptions = useGridOptions(useCroInvestigatorFeesGridOptions);

  const gridRef = useRef<AgGridRef<ApiJSON>>(null);
  // ag-grid returns null, but react seems only to accept undefined
  useImperativeHandle(ref, () => gridRef.current ?? undefined, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: if the data changes or the state of the locked grid changes, we want to redraw the grid
  useEffect(() => {
    gridRef.current?.api?.redrawRows();
  }, [rowData, isGridLocked]);

  return (
    <CondorAgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rowData}
      sx={sx}
      userDisplayOptions={userDisplayOptions}
    />
  );
}

export default withPeriodSpecificGridWrapper(
  forwardRef(CroInvestigatorFeesReconGrid),
  PeriodGridBlobType.CRO_INVESTIGATOR_FEES_RECONCILIATION_GRID,
);
