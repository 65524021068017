import type { ChangeEvent } from 'react';

import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { parse } from 'date-fns/parse';

import DatePicker from 'shared/ui/date-picker/DatePicker';

import {
  selectForecast,
  useReinitializeForecast,
} from 'forecasting/state/slices/forecastSlice';
import type { ForecastedValueResponse } from 'shared/lib/types';

import {
  useGenerateForecastQuery,
  useUpdateForecastMutation,
} from 'shared/api/rtkq/forecasts';

type Props = {
  type: 'PATIENT' | 'SITE';
};

function ForecastDatePicker(props: Props) {
  const { type } = props;
  const forecast = useSelector(selectForecast);
  const [updateForecast] = useUpdateForecastMutation();
  const reInitForecast = useReinitializeForecast();

  const { currentData: forecastGrids } = useGenerateForecastQuery(
    forecast.trace_id,
  );

  function isManualForecastSelected() {
    const predicate = (item: ForecastedValueResponse) =>
      item.input_type === 'MTV';

    if (type === 'SITE') {
      return forecastGrids?.site_activity_grid.some(predicate);
    }

    return forecastGrids?.patient_activity_grid.some(predicate);
  }

  function isSitePerMonthForecastSelected() {
    const predicate = (item: ForecastedValueResponse) =>
      item.input_type === 'RTE';
    if (type === 'SITE') {
      return forecastGrids?.site_activity_grid.some(predicate);
    }

    return forecastGrids?.patient_activity_grid.some(predicate);
  }

  function getMinMonth() {
    if (!isSitePerMonthForecastSelected()) {
      return null;
    }

    const predicate = (item: ForecastedValueResponse) =>
      item.input_type === 'RTE';
    const rows =
      type === 'PATIENT'
        ? forecastGrids?.patient_activity_grid.filter(predicate)
        : forecastGrids?.site_activity_grid.filter(predicate);

    const monthsString = rows
      ?.flatMap((item) => Object.keys(item))
      .filter((key) => key.startsWith('forecasted_month_'))
      .map((key) => key.split('forecasted_month_')[1]);

    if (!monthsString) {
      return;
    }

    const dates = monthsString.map((dateString) =>
      parse(dateString, 'MMM-yyyy', new Date()),
    );

    return Math.max(...dates.map((date) => date.getTime()));
  }

  async function handleChangeEndMonth(event: ChangeEvent<HTMLInputElement>) {
    const key =
      type === 'PATIENT'
        ? 'manual_patients_enrolled_end_date'
        : 'manual_sites_initiated_end_date';

    await updateForecast({
      trace_id: forecast.trace_id,
      [key]: `${event.target.value}-01`,
    });

    reInitForecast();
  }

  function getForecastEndDate() {
    const dateString =
      type === 'PATIENT'
        ? forecast.manual_patients_enrolled_end_date
        : forecast.manual_sites_initiated_end_date;

    if (!dateString) {
      return;
    }

    const [year, month] = dateString.split('-');
    return `${year}-${month}`;
  }

  const tooltipText = `Select the month to which you want to forecast ${type === 'PATIENT' ? 'patients' : 'sites'}. This does not affect the overall trial timeline.`;

  return (
    <DatePicker
      disabled={!isManualForecastSelected()}
      inputProps={{ min: getMinMonth() }}
      size="small"
      type="month"
      value={getForecastEndDate()}
      endAdornment={
        <Tooltip placement="left" title={tooltipText} arrow>
          <span>
            <InfoOutlinedIcon sx={{ fontSize: 16, color: 'text.primary' }} />
          </span>
        </Tooltip>
      }
      startAdornment={
        <Typography color="text.primary" variant="subtitle2">
          End month
        </Typography>
      }
      onChange={(event: ChangeEvent<HTMLInputElement>) =>
        void handleChangeEndMonth(event)
      }
      onKeyDown={(event) => {
        // Allow only arrow keys and prevent all other keyboard inputs
        if (event.key !== 'ArrowUp' && event.key !== 'ArrowDown') {
          event.preventDefault();
        }
      }}
    />
  );
}

export default ForecastDatePicker;
