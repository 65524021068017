import type {
  BaseRequestFields,
  ForecastParameterRequest,
  ForecastParameterResponse,
} from 'shared/lib/types';

import constructApi from './constructApi';
import { FORECASTS_TAG, GENERATE_FORECASTS_TAG } from './forecasts';

const FORCECAST_PARAMETERS_TAG = 'FORCECAST_PARAMETERS';

type UpdateParameterMonthRequest = BaseRequestFields & {
  month: string;
  value: number;
};

const apiEndpointFactory = constructApi<ForecastParameterResponse>(
  'forecastparameters',
).withTags(FORCECAST_PARAMETERS_TAG, [GENERATE_FORECASTS_TAG, FORECASTS_TAG]);
const api = apiEndpointFactory.inject(() => ({
  getForecastParametersByForecast: apiEndpointFactory.getBy('forecast'),
  createForecastParameter: apiEndpointFactory.create<ForecastParameterRequest>([
    GENERATE_FORECASTS_TAG,
    FORECASTS_TAG,
  ]),
  updateForecastParameter: apiEndpointFactory.update([
    GENERATE_FORECASTS_TAG,
    FORECASTS_TAG,
  ]),
  updateForecastParameterMonth:
    apiEndpointFactory.update<UpdateParameterMonthRequest>(
      [GENERATE_FORECASTS_TAG, FORECASTS_TAG],
      'update_parameter_month',
    ),
}));

export const {
  useGetForecastParametersByForecastQuery,
  useCreateForecastParameterMutation,
  useUpdateForecastParameterMutation,
  useUpdateForecastParameterMonthMutation,
} = api;
