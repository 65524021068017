/*
██████╗░██╗░░░░░███████╗░█████╗░░██████╗███████╗  ██████╗░███████╗░█████╗░██████╗░
██╔══██╗██║░░░░░██╔════╝██╔══██╗██╔════╝██╔════╝  ██╔══██╗██╔════╝██╔══██╗██╔══██╗
██████╔╝██║░░░░░█████╗░░███████║╚█████╗░█████╗░░  ██████╔╝█████╗░░███████║██║░░██║
██╔═══╝░██║░░░░░██╔══╝░░██╔══██║░╚═══██╗██╔══╝░░  ██╔══██╗██╔══╝░░██╔══██║██║░░██║
██║░░░░░███████╗███████╗██║░░██║██████╔╝███████╗  ██║░░██║███████╗██║░░██║██████╔╝
╚═╝░░░░░╚══════╝╚══════╝╚═╝░░╚═╝╚═════╝░╚══════╝  ╚═╝░░╚═╝╚══════╝╚═╝░░╚═╝╚═════╝░

This slice should be used in conjunction with the PartnerDashboardLayout component
that knows how to handle when partner is null / undefined, so we can assume
that partner is always defined inside that element, greatly simplifying the codebase.

If you use this slice outside of the PartnerDashboardLayout context, it will NOT handle
the "missing partner" case correctly and is likely a sign you are doing something wrong
(or need to move where PartnerDashboardLayout is being used in the routes).

If you have any questions, please ask in #engineering
*/

import { useCallback } from 'react';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import type { PartnerResponse } from 'shared/lib/types';
import type { RootState } from 'shared/state/store';
/* eslint-disable import/no-restricted-paths -- partner is special as everything uses it */
import { useReinitializePeriod } from 'accruals/state/slices/periodSlice';
import { useReinitializePeriodVersion } from 'accruals/state/slices/periodVersionSlice';
import { useReinitializeForecast } from 'forecasting/state/slices/forecastSlice';
/* eslint-enable import/no-restricted-paths */

import baseApi from 'shared/api/baseApi';
import { getRegisteredTags } from 'shared/api/rtkq/constructApi';

import { useReinitializeTrial } from './trialSlice';
import { getPartnerSafeKeys } from './utils';

type State = { partner: PartnerResponse; initialized: boolean };

const missingPartner = null as unknown as PartnerResponse;

const initialState: State = { partner: missingPartner, initialized: false };

const PARTNER_KEY = 'partner_trace_id';

const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    changePartner: (state, action: PayloadAction<PartnerResponse>) => {
      state.partner = action.payload;
    },
    changeInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
    },
  },
});

const { changePartner, changeInitialized } = partnerSlice.actions;

export { missingPartner };

export const selectPartner = (state: RootState) => state.partner.partner;
export const selectPartnerInitialized = (state: RootState) =>
  state.partner.initialized;

export const partnerReducer = partnerSlice.reducer;

export function useChangePartner() {
  const dispatch = useDispatch();
  const reInitTrial = useReinitializeTrial();
  const reInitForecast = useReinitializeForecast();
  const reInitPeriod = useReinitializePeriod();
  const reInitPeriodVersion = useReinitializePeriodVersion();

  return useCallback(
    (partner: PartnerResponse | null, isInit = false) => {
      if (partner === null) {
        localStorage.removeItem(PARTNER_KEY);
        dispatch(changePartner(missingPartner));
      } else {
        localStorage.setItem(PARTNER_KEY, partner.trace_id);
        dispatch(changePartner(partner));
      }

      // clear all existing RTKQ state to guarantee we're not using stale data, although we
      // don't clear "safe" keys that cannot change, so it reduces loading states.
      //
      // additionally, re-initialize the things "inside" partner so everything updates correctly
      if (!isInit) {
        reInitTrial();
        reInitForecast();
        reInitPeriod();
        reInitPeriodVersion();

        const tagsToClear = getRegisteredTags().filter(
          (tag) => !getPartnerSafeKeys().includes(tag),
        );
        dispatch(baseApi.util.invalidateTags(tagsToClear));
      }
    },
    [dispatch, reInitForecast, reInitPeriod, reInitPeriodVersion, reInitTrial],
  );
}

export function useInitializePartner() {
  const change = useChangePartner();
  const dispatch = useDispatch();

  return useCallback(
    (companyGroups: PartnerResponse[] | undefined) => {
      dispatch(changeInitialized(true));

      // if we have no returned companyGroups, ensure we update state in case what was present was for a partner they don't have access to
      let partner = missingPartner;
      if (companyGroups?.length) {
        const previousPartnerId = localStorage.getItem(PARTNER_KEY) ?? null;
        const previousPartner = companyGroups.find(
          (comp) => comp.trace_id === previousPartnerId,
        );
        // If we didn't find a relevant selected partner in local storage, arbitrarily default to the first one in the list.
        partner = previousPartner ?? companyGroups[0];
      }

      change(partner, true);
    },
    [change, dispatch],
  );
}
