import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { processForecastParameters } from 'forecasting/pages/forecasting/utils/forecastUtils';
import { selectForecast } from 'forecasting/state/slices/forecastSlice';
import { ForecastParameterType } from 'shared/lib/types';

import { useGetForecastParametersByForecastQuery } from 'shared/api/rtkq/forecastparameters';
import { useDefaultParametersQuery } from 'shared/api/rtkq/forecasts';

function useForecastSitesRows() {
  const forecast = useSelector(selectForecast);
  const { currentData: defaultParameters } = useDefaultParametersQuery(
    forecast.trace_id,
  );

  const { currentData: forecastParameters } =
    useGetForecastParametersByForecastQuery(forecast.trace_id);

  return useMemo(() => {
    if (!defaultParameters || !forecastParameters) {
      return [];
    }
    return processForecastParameters(
      defaultParameters,
      forecastParameters,
      'Sites',
      ForecastParameterType.SITES,
    );
  }, [defaultParameters, forecastParameters]);
}

export default useForecastSitesRows;
