import type { ReactNode } from 'react';
import { createContext, useContext, useState } from 'react';

import type { ApiJSON } from 'shared/api/apiJSON';

import type { AgGridRef } from '../ag-grid/CondorAgGrid';

// The only reason we're using a context is because we need to store button ref in react state instead of redux store
// (we should not store non-serializable objects in the store) */

type CommentsPopupContextProps = {
  commentPopupAnchorEl: HTMLElement | null;
  setCommentPopupAnchorEl: (element: HTMLElement | null) => void;
  gridRef: AgGridRef<ApiJSON> | null;
  setGridRef: (ref: AgGridRef<ApiJSON> | null) => void;
};

const CommentsPopupContext = createContext<
  CommentsPopupContextProps | undefined
>(undefined);

export function CommentsPopupProvider({ children }: { children: ReactNode }) {
  const [commentPopupAnchorEl, setCommentPopupAnchorEl] =
    useState<HTMLElement | null>(null);

  const [gridRef, setGridRef] = useState<AgGridRef<ApiJSON> | null>(null);

  return (
    <CommentsPopupContext.Provider
      value={{
        commentPopupAnchorEl,
        setCommentPopupAnchorEl,
        gridRef,
        setGridRef,
      }}
    >
      {children}
    </CommentsPopupContext.Provider>
  );
}

export const useCommentsPopup = (): CommentsPopupContextProps => {
  const context = useContext(CommentsPopupContext);
  if (!context) {
    return {
      commentPopupAnchorEl: null,
      setCommentPopupAnchorEl: () => null,
      gridRef: null,
      setGridRef: () => null,
    };
  }
  return context;
};
