import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { selectForecast } from 'forecasting/state/slices/forecastSlice';
import { differenceInMonthsFractional } from 'shared/helpers/helpers';
import type { ForecastParameterResponse } from 'shared/lib/types';
import { ForecastParameterType, TIMELINE_PARAMETERS } from 'shared/lib/types';

import { useGetForecastParametersByForecastQuery } from 'shared/api/rtkq/forecastparameters';
import { useDefaultParametersQuery } from 'shared/api/rtkq/forecasts';

function calculateMonthsTotal(
  startDate: string,
  endDate: string,
): number | string {
  const months = differenceInMonthsFractional(
    new Date(startDate),
    new Date(endDate),
  );
  return months > 0 ? months : '—';
}

function createTimelineRow(
  assumption: string,
  defaultParam: { START: string; END: string },
  forecastParam: Partial<ForecastParameterResponse> | undefined,
  parameterType: ForecastParameterType | null,
  isTotal = false,
) {
  const isStart = assumption.toLowerCase().endsWith('start');
  const actualDate = defaultParam[isStart ? 'START' : 'END'];
  const forecastDate =
    forecastParam?.[isStart ? 'start_date' : 'end_date'] ?? actualDate;

  return {
    assumption,
    actual: isTotal
      ? calculateMonthsTotal(defaultParam.START, defaultParam.END)
      : actualDate,
    forecast: isTotal
      ? calculateMonthsTotal(
          forecastParam?.start_date ?? defaultParam.START,
          forecastParam?.end_date ?? defaultParam.END,
        )
      : forecastDate,
    isTotal,
    parameterType,
    parameter_trace_id: forecastParam?.trace_id ?? null,
  };
}

type TimelineRow = {
  assumption: string;
  actual: number | string;
  forecast: number | string;
  isTotal: boolean;
  parameterType: ForecastParameterType | null;
  parameter_trace_id: string | null;
};

function useForecastTimelineRows(): TimelineRow[] {
  const forecast = useSelector(selectForecast);
  const { currentData: defaultParameters } = useDefaultParametersQuery(
    forecast.trace_id,
  );
  const { currentData: forecastParameters } =
    useGetForecastParametersByForecastQuery(forecast.trace_id);

  return useMemo(() => {
    if (!defaultParameters) {
      return [];
    }

    const parameters = Object.entries(TIMELINE_PARAMETERS) as Array<
      [keyof typeof TIMELINE_PARAMETERS, string]
    >;

    const rows = parameters.flatMap(([type, label]) => {
      const defaultParam = defaultParameters[type];
      const forecastParam = forecastParameters?.find((fp) => fp.type === type);

      return [
        createTimelineRow(`${label} start`, defaultParam, forecastParam, type),
        createTimelineRow(`${label} end`, defaultParam, forecastParam, type),
        createTimelineRow(
          `${label} months total`,
          defaultParam,
          forecastParam,
          type,
          true,
        ),
      ];
    });

    // Re-use the same logic for calculating months - difference between start/end dates
    const startUpParam = defaultParameters[ForecastParameterType.STARTUP];
    const closeParam = defaultParameters[ForecastParameterType.CLOSE];
    const forecastStartUpParam = forecastParameters?.find(
      (fp) => fp.type === ForecastParameterType.STARTUP,
    );
    const forecastCloseParam = forecastParameters?.find(
      (fp) => fp.type === ForecastParameterType.CLOSE,
    );

    rows.push(
      createTimelineRow(
        'Overall start',
        startUpParam,
        forecastStartUpParam,
        null,
      ),
      createTimelineRow('Overall end', closeParam, forecastCloseParam, null),
      createTimelineRow(
        'Overall months total',
        { ...startUpParam, END: closeParam.END },
        {
          trace_id: '',
          start_date: forecastStartUpParam?.start_date ?? startUpParam.START,
          end_date: forecastCloseParam?.end_date ?? closeParam.END,
        },
        null,
        true,
      ),
    );

    return rows;
  }, [defaultParameters, forecastParameters]);
}

export default useForecastTimelineRows;
