import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { selectForecast } from 'forecasting/state/slices/forecastSlice';
import { ForecastParameterType } from 'shared/lib/types';

import { useGetForecastParametersByForecastQuery } from 'shared/api/rtkq/forecastparameters';
import { useDefaultParametersQuery } from 'shared/api/rtkq/forecasts';

function useForecastPatientMonthRows() {
  const forecast = useSelector(selectForecast);
  const { currentData: defaultParameters } = useDefaultParametersQuery(
    forecast.trace_id,
  );
  const { currentData: forecastParameters } =
    useGetForecastParametersByForecastQuery(forecast.trace_id);

  return useMemo(() => {
    const defaultPatientMonthsThroughput =
      defaultParameters?.[ForecastParameterType.PATIENT_THROUGHPUT]
        .patient_throughput ?? 0;
    const patientMonths =
      defaultParameters?.[ForecastParameterType.PATIENT_MONTHS];
    const patientMonthsTotal = Object.values(patientMonths ?? {}).reduce(
      (acc, value) => acc + value,
      0,
    );

    const forecastedThroughputParam = forecastParameters?.filter(
      (parameter) =>
        parameter.type === ForecastParameterType.PATIENT_THROUGHPUT,
    )?.[0];
    const forecastedThroughput =
      forecastedThroughputParam?.value ?? defaultPatientMonthsThroughput;

    const patientForecastParameters = forecastParameters?.filter(
      (parameter) => parameter.type === ForecastParameterType.PATIENTS_ENROLLED,
    );

    const forecastedPatientMonths: Record<string, number> = {};
    let forecastedPatientMonthsTotal = 0;
    for (const siteParam of patientForecastParameters ?? []) {
      forecastedPatientMonths[siteParam.region_name] =
        siteParam.value * forecastedThroughput;
      forecastedPatientMonthsTotal +=
        forecastedPatientMonths[siteParam.region_name];
    }

    return [
      {
        assumption: 'Expected patient throughput (months) global',
        actual: defaultPatientMonthsThroughput,
        forecast: forecastedThroughput,
        parameterType: ForecastParameterType.PATIENT_THROUGHPUT,
        parameter_trace_id: forecastedThroughputParam?.trace_id,
      },
      {
        isEmpty: true,
      },
      ...Object.entries(patientMonths ?? {}).map(([region, value]) => ({
        assumption: `Patient months ${region}`,
        actual: value,
        forecast: forecastedPatientMonths[region],
      })),
      {
        assumption: 'Patient months global total',
        actual: patientMonthsTotal,
        forecast: forecastedPatientMonthsTotal,
        isTotal: true,
      },
    ];
  }, [defaultParameters, forecastParameters]);
}

export default useForecastPatientMonthRows;
