import useIsReadOnlyPermission from 'shared/lib/permissions/useIsReadOnlyPermission';

import type { CondorGridOptions } from '../types';

export default function useGridOptions<TReturn, TProps extends unknown[]>(
  gridOptionsHook: (
    isOpenPeriod: boolean,
    ...rest: TProps
  ) => CondorGridOptions<TReturn> | undefined,
  params: TProps = [] as unknown as TProps,
): CondorGridOptions<TReturn> | undefined {
  const isReadOnlyPermission = useIsReadOnlyPermission();
  return gridOptionsHook.apply(null, [!isReadOnlyPermission, ...params]);
}
