import { useMemo } from 'react';

import {
  getGenericCellConfig,
  getNumberCellConfig,
  getSelectCellConfig,
} from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import { VISIT_TYPE_OPTIONS } from 'accruals/wizards/visit-schedules/steps/components/VisitItem';
import type {
  PatientAssessment,
  PatientAssessmentEditor,
  PatientCohort,
  TraceId,
} from 'shared/lib/types';

import VisitsCohortMenu from './components/VisitsCohortMenu';

function useVisitsCohortColumnDefs(
  _: boolean,
  cohortId: TraceId,
  isGridLocked: boolean,
): Array<CondorColDef<PatientAssessment | PatientAssessmentEditor>> {
  const canEditData = !isGridLocked;

  const visitTypeMappings = VISIT_TYPE_OPTIONS.reduce<{
    [key: string]: string;
  }>((acc, option) => {
    acc[option.value] = option.label;
    return acc;
  }, {});

  const timelinePortionMappings = {
    treatment: 'Treatment',
    follow_up: 'Follow-up',
    other: 'Other',
  };

  return useMemo(
    () => [
      ...(canEditData
        ? [
            {
              sortable: false,
              resizable: false,
              suppressHeaderMenuButton: true,
              width: 50,
              cellClass: 'ag-cell-button',
              rowDrag: true,
            },
            {
              sortable: false,
              resizable: false,
              suppressHeaderMenuButton: true,
              width: 50,
              cellClass: 'ag-cell-button',
              cellRenderer: ({
                data,
                context,
              }: {
                context: { cohort: PatientCohort };
                data: PatientAssessment;
              }) => VisitsCohortMenu(data, context.cohort),
            },
          ]
        : []),
      {
        field: 'traceId',
        headerName: 'ID',
        hide: true,
      },
      {
        field: 'name',
        headerName: 'Visit name',
        flex: 1,
        valueGetter: 'data.name ? data.name : null', // properly show placeholder for empty value
        ...getGenericCellConfig(),
        ...(canEditData && { editable: true }),
        cellRendererSelector: 'visitNameCellRendererSelector',
      },
      {
        headerName: 'Visit type',
        refData: visitTypeMappings,
        valueGetter: `
        {
          const { isDroppedCompleted, isEnroll, isScreen, isScreenfail } = data;
          if (isDroppedCompleted) {
            return 'isDroppedCompleted';
          }
          if (isEnroll) {
            return 'isEnroll';
          }
          if (isScreen) {
            return 'isScreen';
          }
          if (isScreenfail) {
            return 'isScreenfail';
          }
          return 'isRegular';
        }
        `,
        ...getSelectCellConfig(),
        cellEditorParams() {
          return {
            values: VISIT_TYPE_OPTIONS.map((option) => option.value),
          };
        },
        flex: 1,
        ...(canEditData && { editable: true }),
      },
      {
        field: 'timelinePortion',
        headerName: 'Conduct portion',
        flex: 1,
        refData: timelinePortionMappings,
        ...getSelectCellConfig(),
        cellEditorParams() {
          return {
            values: ['treatment', 'follow_up', 'other'],
          };
        },
        ...(canEditData && { editable: true }),
      },
      {
        field: 'dayOfProtocol',
        headerName: 'Day of protocol',
        flex: 1,
        valueGetter: `
        {
          if (data.isEnroll) {
            return '1';
          }

          return data.dayOfProtocol;
        }
        `,
        ...getNumberCellConfig({
          useEmDash: false,
        }),
        ...(canEditData && { editable: true }),
      },
      {
        field: 'pizzaName',
        headerName: 'EDC name',
        flex: 1,
        ...getGenericCellConfig(),
        ...(canEditData && { editable: true }),
      },
    ],
    [canEditData, cohortId],
  );
}

export default useVisitsCohortColumnDefs;
