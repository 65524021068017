import type { ReactNode } from 'react';
import { cloneElement, isValidElement, useState } from 'react';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import type { SxProps } from '@mui/material/styles';

import PatientSiteActivityGrid from '../grids/PatientSiteActivityGrid';
import ForecastDatePicker from './forecast-date-picker/ForecastDatePicker';

type Props = {
  gridSx?: SxProps;
  header: ReactNode;
  type: 'PATIENT' | 'SITE';
};

function PatientSiteActivityContainer(props: Props) {
  const { type, gridSx, header } = props;
  const [showMonthlyActuals, setShowMonthlyActuals] = useState<boolean>(false);
  const { mt, ...restProps } = isValidElement(header)
    ? header.props
    : { mt: null };
  const modifiedHeader = isValidElement(header)
    ? cloneElement(header, {
        ...restProps,
        flex: 1,
        mt: null,
      })
    : header;

  return (
    <>
      <Box alignItems="center" display="flex" mt={mt}>
        {modifiedHeader}
        <FormControlLabel
          label="Show monthly actuals"
          control={
            <Switch
              checked={showMonthlyActuals}
              onChange={(_, checked) => setShowMonthlyActuals(checked)}
            />
          }
        />
        <ForecastDatePicker type={type} />
      </Box>
      <PatientSiteActivityGrid
        showActuals={showMonthlyActuals}
        sx={gridSx}
        type={type}
      />
    </>
  );
}

export default PatientSiteActivityContainer;
