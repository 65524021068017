import type { ReactElement } from 'react';

import Box from '@mui/material/Box';

import type { AgGridRef } from 'shared/components/ag-grid/CondorAgGrid';
import CommentsButton from 'shared/components/comments/CommentsButton';
import usePageCommentsCountLoader from 'shared/components/comments/hooks/usePageCommentsCountLoader';

import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';
import { CommentLocationType } from 'shared/lib/types';

import type { ApiJSON } from 'shared/api/apiJSON';

import UploadEdcSnapshots from '../buttons/UploadEdcSnapshots';

type Props = { gridRef?: AgGridRef<ApiJSON> | null };

function PatientActivityHeader(props: Props): ReactElement {
  const { gridRef } = props;
  const commentsCount = usePageCommentsCountLoader(
    CommentLocationType.PATIENT_ACTIVITY,
  );
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 1,
        mb: 2,
        flexGrow: 0,
        flexShrink: 0,
      }}
    >
      <UploadEdcSnapshots />
      <CommentsButton
        commentsCount={commentsCount}
        gridRef={gridRef}
        location={CommentLocationType.PATIENT_ACTIVITY}
      />
    </Box>
  );
}

export default withPeriodSpecificGenericWrapper(PatientActivityHeader);
