import { useMemo } from 'react';

import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';

import usePatientSiteActivityColDefs from '../hooks/usePatientSiteActivityColDefs';
import usePatientSiteActivityGridOptions from '../hooks/usePatientSiteActivityGridOptions';
import usePatientSiteActivityRows from '../hooks/usePatientSiteActivityRows';

type Props = {
  overlayNoRowsTemplate?: string;
  showActuals: boolean;
  sx?: SxProps;
  type: 'PATIENT' | 'SITE';
};

function PatientSiteActivityGrid(props: Props) {
  const { overlayNoRowsTemplate, sx, showActuals, type } = props;
  const colDefs = useGridColDefs(usePatientSiteActivityColDefs, [
    type,
    showActuals,
  ]);
  const rows = usePatientSiteActivityRows(type);
  const gridOptions = useGridOptions(usePatientSiteActivityGridOptions);

  const sxGrid = useMemo(
    () => ({
      ...sx,
      ...(!rows?.length && { height: '200px' }),
    }),
    [sx, rows],
  );

  return (
    <CondorAgGrid
      columnDefs={colDefs}
      domLayout={rows?.length ? 'autoHeight' : 'normal'}
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rows}
      sx={sxGrid}
    />
  );
}

export default PatientSiteActivityGrid;
