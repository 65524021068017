import { useMemo } from 'react';

import type {
  CellEditingStartedEvent,
  NewValueParams,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { useTheme } from '@mui/material/styles';
import { skipToken } from '@reduxjs/toolkit/query';
import { useSelector } from 'react-redux';

import {
  getDateCellConfig,
  getNullOrDecimalConfig,
  getSelectCellConfig,
  getToggleableMoneyCellConfig,
  getTrialMoneyCellConfig,
  makeEditableIf,
} from 'shared/components/ag-grid-cells/config';
import type {
  CondorColDef,
  CondorColGroupDef,
} from 'shared/components/ag-grid/types';

import {
  ACTIVITY_DRIVER_ASSIGNMENTS,
  FORECASTED_TOTAL,
  LTD_EXPENSE,
  MONTHS,
} from 'shared/columnHeaders';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import { getCroUnitTypeChoices } from 'shared/hook-helpers/processCroExpenseGridColumnDefs';
import { OCC_UNIT_TYPE_CHOICES } from 'shared/hook-helpers/processOccExpenseGridColumnDefs';
import { processRegionsAndGroupsListInGrids } from 'shared/hook-helpers/processRegionsAndGroupsListInGrids';
import {
  ActivityUnitDetail,
  ActivityUnitType,
  detailChoicesForUnitType,
} from 'shared/lib/driverUnitTypes';
import type {
  CroCostCategory,
  ForecastedGridsResponse,
  RegionAndRegionGroupResponse,
} from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetCurrentContractVersionQuery } from 'shared/api/rtkq/contractcontainers';
import {
  useGetRegionGroupsByContractVersionQuery,
  useGetRegionGroupsByTrialQuery,
} from 'shared/api/rtkq/regiongroups';
import {
  useGetContractRegionsByTrialQuery,
  useGetRegionsByContractVersionQuery,
} from 'shared/api/rtkq/regions';

import { forecastMonthColDefs } from './forecastMonthColDefs';
import useForecast from './useForecast';

const ASSIGNED_REGION_WIDTH = 150;

function processForecastedExpenseGridColumnDefs(
  _isOpenPeriod: boolean,
  baseColDefs: Array<CondorColDef | CondorColGroupDef> | undefined,
  generatedForecast: ForecastedGridsResponse | undefined,
  showActuals: boolean,
  isForecastedActivityDriverEnabled: boolean,
  costCategory: CroCostCategory | null,
  regionsAndRegionGroups: RegionAndRegionGroupResponse[],
  themeMode?: 'dark' | 'light',
): Array<CondorColDef | CondorColGroupDef> {
  if (!baseColDefs) {
    return [];
  }

  const copyOfBaseColDefs = JSON.parse(JSON.stringify(baseColDefs)) as Array<
    CondorColDef | CondorColGroupDef
  >;

  if (!generatedForecast?.expenses.length) {
    return baseColDefs;
  }

  const forecastedMonths = Object.keys(generatedForecast.expenses[0]).filter(
    (key) => key.startsWith('forecasted_month_'),
  );

  if (isForecastedActivityDriverEnabled) {
    const index = copyOfBaseColDefs.findIndex(
      (colDef) => colDef.headerName === ACTIVITY_DRIVER_ASSIGNMENTS,
    );

    if (index !== -1) {
      const forecastActivityDriverGroup = {
        // these are different enough from the actual activity driver columns so defining from scratch here
        headerName: `${ACTIVITY_DRIVER_ASSIGNMENTS} - forecasted`,
        children: [
          {
            field: 'forecast_activityDriverTraceId',
            hide: true,
          },
          {
            headerName: 'Unit Type',
            field: 'forecast_unitType',
            filter: true,
            ...getSelectCellConfig({ useEmDashInTotal: false }),
            cellEditorParams: {
              values: (costCategory
                ? getCroUnitTypeChoices(costCategory)
                : OCC_UNIT_TYPE_CHOICES
              ).filter(
                (unitType) =>
                  // Temporarily exclude these drivers until we support editing of the forecasted monthly values
                  unitType !== ActivityUnitType.AS_INVOICED &&
                  unitType !== ActivityUnitType.PERCENT_COMPLETE,
              ),
            },
            onCellValueChanged(event: NewValueParams) {
              event.node?.setDataValue('forecast_unitDetail', null);
            },
            ...makeEditableIf(
              ({ data }) => data && data.type !== 'BOTTOM_LINE_ADJUSTMENT',
              themeMode,
            ),
          },
          {
            headerName: 'Unit Detail',
            field: 'forecast_unitDetail',
            filter: true,
            ...getSelectCellConfig({ useEmDashInTotal: false }),
            cellEditorParams(params: CellEditingStartedEvent) {
              return {
                values: detailChoicesForUnitType(
                  params.data.forecast_unitType,
                  'CRO',
                ).filter(
                  (unitDetail) =>
                    (unitDetail !== ActivityUnitDetail.ENROLLMENT_PERIOD ||
                      params.data.hasEnrollment === true) &&
                    (unitDetail !== ActivityUnitDetail.TREATMENT_PERIOD ||
                      params.data.hasTreatment === true) &&
                    (unitDetail !== ActivityUnitDetail.FOLLOW_UP_PERIOD ||
                      params.data.hasFollowup === true),
                ),
              };
            },
            ...makeEditableIf(
              ({ data }) =>
                data?.forecast_unitType &&
                data.type !== 'BOTTOM_LINE_ADJUSTMENT',
              themeMode,
            ),
          },
          {
            headerName: 'Start Date',
            field: 'forecast_startDate',
            ...getDateCellConfig({ useEmDashInTotal: false }),
            ...makeEditableIf(
              ({ data }) =>
                data?.forecast_unitDetail === ActivityUnitDetail.CUSTOM,
              themeMode,
            ),
          },
          {
            headerName: 'End Date',
            field: 'forecast_endDate',
            ...getDateCellConfig({ useEmDashInTotal: false }),
            ...makeEditableIf(
              ({ data }) =>
                data?.forecast_unitDetail === ActivityUnitDetail.CUSTOM,
              themeMode,
            ),
          },
          ...(costCategory != null
            ? [
                {
                  headerName: 'Assigned Region',
                  field: 'forecast_assignedRegion',
                  width: ASSIGNED_REGION_WIDTH,
                  editable: true,
                  ...getSelectCellConfig(),
                  valueFormatter: (params: ValueFormatterParams) =>
                    regionsAndRegionGroups.find(
                      (regionAndRegionGroup) =>
                        regionAndRegionGroup.trace_id === params.value,
                    )?.name ?? '',
                  cellEditorParams: {
                    options: regionsAndRegionGroups.map(
                      (regionAndRegionGroup) => ({
                        label: regionAndRegionGroup.name,
                        value: regionAndRegionGroup.trace_id,
                      }),
                    ),
                  },
                },
              ]
            : []),
          {
            field: 'forecast_unitCount',
            headerName: '# of units',
            ...getNullOrDecimalConfig(),
          },
          {
            headerName: 'Unit price',
            ...getToggleableMoneyCellConfig('unit_price', {
              cellRendererParams: { useEmDashInTotal: false },
            }),
          },
          {
            headerName: 'Forecasted contract value',
            valueGetter:
              'data.forecast_unitCount * data.parameterCalculatedUnitCount',
            aggFunc: 'sum',
            ...getTrialMoneyCellConfig({ useEmDashInTotal: false }),
          },
        ],
      };

      copyOfBaseColDefs.splice(index + 1, 0, forecastActivityDriverGroup);
    }
  }

  const ltdExpenseColumnGroup = copyOfBaseColDefs.find(
    (colDef) => colDef.headerName === LTD_EXPENSE,
  ) as CondorColGroupDef | undefined;

  if (ltdExpenseColumnGroup) {
    const forecastTotalColumn = ltdExpenseColumnGroup.children.find(
      (child) => child.headerName === FORECASTED_TOTAL,
    );
    if (!forecastTotalColumn) {
      ltdExpenseColumnGroup.children.push({
        headerName: FORECASTED_TOTAL,
        field: 'forecasted_total',
        aggFunc: 'sum',
        ...getTrialMoneyCellConfig(),
      });
    }
  }

  copyOfBaseColDefs.push({
    headerName: 'Forecasted Monthly Expense',
    children: forecastMonthColDefs(forecastedMonths, true),
  });

  return copyOfBaseColDefs.map((colDef) => {
    const colDefModified = { ...colDef };

    if (colDef.headerName === MONTHS) {
      colDefModified.headerName = 'Actual Monthly Expense';
      for (const childColumn of (colDefModified as CondorColGroupDef)
        .children as CondorColDef[]) {
        childColumn.hide ||= !showActuals;
      }
    } else if (colDefModified.headerName === ACTIVITY_DRIVER_ASSIGNMENTS) {
      colDefModified.headerName = `${colDef.headerName} - actuals`;
      for (const childColumn of (colDefModified as CondorColGroupDef)
        .children as CondorColDef[]) {
        childColumn.hide ||= !isForecastedActivityDriverEnabled || !showActuals;
      }
    }

    return colDefModified;
  });
}

export default function useForecastedExpenseGridColumnDefs(
  isOpenPeriod: boolean,
  baseColDefs: Array<CondorColDef | CondorColGroupDef> | undefined,
  generatedForecast: ForecastedGridsResponse | undefined,
  showActuals: boolean,
  costCategory: CroCostCategory | null,
  contractContainerSlug: string,
): Array<CondorColDef | CondorColGroupDef> {
  const isForecastActivityDriverEnabled = useFeatureFlag(
    'forecast_activity_driver',
  );
  const { palette } = useTheme();

  const trial = useSelector(selectTrial);

  const { period } = useForecast();
  const isOcc = contractContainerSlug === 'occ';
  const { data: currentContract } = useGetCurrentContractVersionQuery(
    !isOcc && period
      ? {
          trace_id: contractContainerSlug,
          otherParameter: period.trace_id,
        }
      : skipToken,
  );

  const { currentData: croRegions } = useGetRegionsByContractVersionQuery(
    currentContract?.trace_id ?? skipToken,
  );
  const { currentData: croRegionGroups } =
    useGetRegionGroupsByContractVersionQuery(
      currentContract?.trace_id ?? skipToken,
    );
  const { currentData: occRegions } = useGetContractRegionsByTrialQuery(
    isOcc ? trial.trace_id : skipToken,
  );
  const { currentData: occRegionGroups } = useGetRegionGroupsByTrialQuery(
    isOcc ? trial.trace_id : skipToken,
  );
  const regionsAndRegionGroups = processRegionsAndGroupsListInGrids(
    isOcc ? occRegions : croRegions,
    isOcc ? occRegionGroups : croRegionGroups,
  );

  return useMemo(
    () =>
      processForecastedExpenseGridColumnDefs(
        isOpenPeriod,
        baseColDefs,
        generatedForecast,
        showActuals,
        isForecastActivityDriverEnabled,
        costCategory,
        regionsAndRegionGroups,
        palette.mode,
      ),
    [
      isOpenPeriod,
      baseColDefs,
      generatedForecast,
      showActuals,
      isForecastActivityDriverEnabled,
      costCategory,
      regionsAndRegionGroups,
      palette.mode,
    ],
  );
}
