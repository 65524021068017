import { useEffect, useState } from 'react';

import { getJSONFromFile } from 'shared/helpers/helpers';
import type { PeriodMenuItemResponse } from 'shared/lib/types';

import type { ApiJSON } from 'shared/api/apiJSON';

export default function usePeriodMenuItemBlob(
  periodMenuItem: PeriodMenuItemResponse | undefined,
): ApiJSON | undefined {
  const [blobData, setBlobData] = useState<ApiJSON>();

  const blobPath = periodMenuItem?.blob;

  useEffect(() => {
    void (async () => {
      if (blobPath) {
        setBlobData(await getJSONFromFile<ApiJSON>(blobPath));
      }
    })();
  }, [blobPath]);

  return blobData;
}
