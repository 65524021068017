import { useMemo } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';
import cloneDeep from 'lodash/cloneDeep';

import type { InvestigatorFeesGridResponse, TraceId } from 'shared/lib/types';

import type { ApiJSON } from 'shared/api/apiJSON';
import { useGetContractInvestigatorFeesReconGridQuery } from 'shared/api/rtkq/contracts';

export function processInvestigatorFeesReconRows(
  data: InvestigatorFeesGridResponse | undefined,
  loading: boolean,
): ApiJSON[] | undefined {
  if (loading) {
    return undefined;
  }

  // if there is no data contract version, we'll never even try to make the call, so don't get stuck loading
  if (data === undefined) {
    return [];
  }

  return cloneDeep(
    data.rows.map((row) => ({
      ...row,
      expected_visit_costs: row.expected_visit_costs ?? 0,
      actual_visit_costs: row.actual_visit_costs ?? 0,
      total_admin: row.total_admin ?? 0,
      total_procedures: row.total_procedures ?? 0,
    })),
  );
}

export default function useCroInvestigatorFeesReconRows(
  contractVersionTraceId: TraceId | undefined,
  periodTraceId: TraceId | undefined,
  loadingContractInformation: boolean,
) {
  const makeDataRequest =
    contractVersionTraceId !== undefined && periodTraceId !== undefined;

  const { currentData: data, isLoading } =
    useGetContractInvestigatorFeesReconGridQuery(
      makeDataRequest
        ? { trace_id: contractVersionTraceId, otherParameter: periodTraceId }
        : skipToken,
    );

  return useMemo(
    () =>
      processInvestigatorFeesReconRows(
        data,
        loadingContractInformation || isLoading,
      ),
    [data, isLoading, loadingContractInformation],
  );
}
