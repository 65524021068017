import { useMemo } from 'react';

import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import { makeEditableIf } from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import { selectForecast } from 'forecasting/state/slices/forecastSlice';
import type { ForecastParameterType } from 'shared/lib/types';

import {
  useCreateForecastParameterMutation,
  useUpdateForecastParameterMutation,
} from 'shared/api/rtkq/forecastparameters';

/**
 * @deprecated Use useForecastParamViewerColDefs instead.
 */
const useForecastParamEditorColDefs = (
  type: ForecastParameterType,
  firstColumnHeader: string,
  iconTooltipMessage: string | undefined,
): CondorColDef[] => {
  const forecast = useSelector(selectForecast);
  const [createForecastParameter] = useCreateForecastParameterMutation();
  const [updateForecastParameter] = useUpdateForecastParameterMutation();
  const themeMode = useTheme().palette.mode;

  return useMemo(() => {
    const handleParameterUpsert = (
      parameterTraceId: string | undefined,
      regionName: string,
      newValue?: number,
    ) => {
      void (async () => {
        if (!parameterTraceId) {
          await createForecastParameter({
            forecast: forecast.trace_id,
            type,
            region_name: regionName,
            value: newValue,
          }).unwrap();
        } else {
          await updateForecastParameter({
            trace_id: parameterTraceId,
            value: newValue ?? 0,
          }).unwrap();
        }
      })();
    };

    return [
      {
        field: 'region_name',
        flex: 1,
        headerName: firstColumnHeader,
        ...(iconTooltipMessage && {
          headerComponent: 'AgGridForecastParameterHeader',
          headerComponentParams: {
            context: {
              iconTooltipMessage,
            },
          },
        }),
      },
      {
        field: 'actual',
        flex: 1,
        headerName: 'Actual',
      },
      {
        field: 'forecast',
        flex: 1,
        headerName: 'Forecast',
        ...makeEditableIf(() => !forecast.locked, themeMode),
        ...(!forecast.locked && {
          onCellValueChanged: (event) => {
            handleParameterUpsert(
              event.data.parameter_trace_id,
              event.data.region_name,
              event.newValue,
            );
          },
        }),
      },
      { field: 'parameter_trace_id', hide: true },
    ];
  }, [
    forecast,
    createForecastParameter,
    updateForecastParameter,
    type,
    firstColumnHeader,
    iconTooltipMessage,
    themeMode,
  ]);
};

export default useForecastParamEditorColDefs;
