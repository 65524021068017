import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import GradientCircularProgress from 'shared/components/progress-bar/GradientCircularProgress';

import { type IntegrationResponse } from 'shared/lib/types';
import WizardStep from 'shared/wizards/steps/WizardStep';
import UploadErrorImportSection from 'shared/wizards/upload-unmapped-step/import/UploadErrorImportSection';
import UploadSuccessImportSection from 'shared/wizards/upload-unmapped-step/import/UploadSuccessImportSection';

import { useGetDataFromIntegrationQuery } from 'shared/api/rtkq/integrations';

type Props = {
  integration: IntegrationResponse;
};

function ImportLoadingStep(props: Props) {
  const { integration } = props;

  const { isFetching, isSuccess, currentData, isError, error } =
    useGetDataFromIntegrationQuery(integration.trace_id, {
      refetchOnMountOrArgChange: true,
    });

  return (
    <WizardStep disableNextButton={isFetching || isError}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 10,
          width: '100%',
          height: '100%',
        }}
      >
        {isFetching && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 10,
            }}
          >
            <GradientCircularProgress />
            <Typography variant="h4">
              Importing data from {integration.vendor_name}
            </Typography>
          </Box>
        )}
        {isSuccess && !isFetching && (
          <UploadSuccessImportSection
            importedRowsCount={currentData.records_imported}
          />
        )}

        {isError && <UploadErrorImportSection error={error} />}
      </Box>
    </WizardStep>
  );
}

export default ImportLoadingStep;
