import { type ForwardedRef, type ReactElement, forwardRef } from 'react';

import type { SxProps } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import type { AgGridRef } from 'shared/components/ag-grid/CondorAgGrid';
import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';

import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import { PeriodGridBlobType } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import type { ApiJSON } from 'shared/api/apiJSON';
import { useGetPatientCohortsByTrialQuery } from 'shared/api/rtkq/patientcohorts';

import usePatientJourneyColumnDefs from '../hooks/usePatientJourneyColumnDefs';
import usePatientJourneyGridOptions from '../hooks/usePatientJourneyGridOptions';
import usePatientJourneyGridRows from '../hooks/usePatientJourneyGridRows';

type Props = {
  overlayNoRowsTemplate?: string;
  showExpectedVisits?: boolean;
  sx?: SxProps;
};

function PatientJourneyGrid(
  props: Props,
  ref: ForwardedRef<AgGridRef<ApiJSON> | undefined>,
): ReactElement {
  const { sx, overlayNoRowsTemplate, showExpectedVisits } = props;

  const { patientCohortId: patientCohortTraceId } = useParams();
  const trial = useSelector(selectTrial);
  const { currentData: patientCohorts } = useGetPatientCohortsByTrialQuery(
    trial.trace_id,
  );

  const currentCohort = patientCohorts?.find(
    (cohort) => cohort.trace_id === patientCohortTraceId,
  );

  const columnDefs = useGridColDefs(usePatientJourneyColumnDefs, [
    currentCohort?.trace_id,
  ]);
  const gridOptions = useGridOptions(usePatientJourneyGridOptions);
  const rowData = usePatientJourneyGridRows(
    currentCohort?.trace_id,
    showExpectedVisits ?? false,
  );

  return (
    <CondorAgGrid
      ref={ref}
      columnDefs={columnDefs}
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rowData}
      sx={sx}
    />
  );
}

export default withPeriodSpecificGridWrapper(
  forwardRef(PatientJourneyGrid),
  PeriodGridBlobType.PATIENT_JOURNEY,
);
