import type { ForwardedRef, ReactElement } from 'react';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import Snackbar from '@mui/material/Snackbar';
import type { SxProps } from '@mui/material/styles';

import type { AgGridRef } from 'shared/components/ag-grid/CondorAgGrid';
import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';
import useLockedGridStatus from 'shared/components/lock-grid/useLockedGridStatus';
import Alert from 'shared/ui/alert/Alert';

import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import type { SiteGridRow } from 'shared/lib/types';
import { LockGrid, PeriodGridBlobType } from 'shared/lib/types';

import type { ApiJSON } from 'shared/api/apiJSON';

import useSiteCostMatrixGridOptions from '../hooks/useSiteCostMatrixGridOptions';
import useSiteCostMatrixRows from '../hooks/useSiteCostMatrixRows';
import useSiteLabCostMatrixColumnDefs from '../hooks/useSiteLabCostMatrixColumnDefs';

type Props = { overlayNoRowsTemplate?: string; sx?: SxProps };

function SiteGrid(
  props: Props,
  ref: ForwardedRef<AgGridRef<ApiJSON> | undefined>,
): ReactElement {
  const { overlayNoRowsTemplate, sx } = props;
  const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false);

  const isGridLocked = useLockedGridStatus(LockGrid.SITE_COST_MATRIX, null);
  const columnDefs = useGridColDefs(
    useSiteLabCostMatrixColumnDefs,
    ['SITE', isGridLocked],
    isGridLocked,
  );
  const rowData = useSiteCostMatrixRows();
  const gridOptions = useGridOptions(useSiteCostMatrixGridOptions, [
    () => setShowSuccessMsg(true),
  ]);

  const gridRef = useRef<AgGridRef<SiteGridRow>>(null);
  // ag-grid returns null, but react seems only to accept undefined
  useImperativeHandle(ref, () => gridRef.current ?? undefined, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: if the data changes or the state of the locked grid changes, we want to redraw the grid
  useEffect(() => {
    gridRef.current?.api?.redrawRows();
  }, [rowData, isGridLocked]);

  // if gridOptions are not yet defined, don't render the grid as
  // ag-grid INTENTIONALLY does not update that after render
  if (gridOptions === undefined) {
    return <div />;
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={4000}
        open={showSuccessMsg}
        onClose={() => setShowSuccessMsg(false)}
      >
        <Alert severity="success">
          A new version of the site has been added.
        </Alert>
      </Snackbar>
      <CondorAgGrid
        ref={gridRef}
        columnDefs={columnDefs}
        gridOptions={gridOptions}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        rowData={rowData}
        sx={sx}
      />
    </>
  );
}

export default withPeriodSpecificGridWrapper(
  forwardRef(SiteGrid),
  PeriodGridBlobType.SITE_COST_MATRIX,
);
