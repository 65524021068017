import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';

import { selectForecast } from 'forecasting/state/slices/forecastSlice';
import type {
  ForecastParameterRow,
  NonTimelineParameterType,
} from 'shared/lib/types';

import { useGetForecastParametersByForecastQuery } from 'shared/api/rtkq/forecastparameters';
import { useDefaultParametersQuery } from 'shared/api/rtkq/forecasts';
import { useGetCurrentRegionsQuery } from 'shared/api/rtkq/regions';

const useForecastParamEditorRows = (
  paramType: NonTimelineParameterType,
): ForecastParameterRow[] => {
  const forecast = useSelector(selectForecast);

  const { currentData: forecastParameters } =
    useGetForecastParametersByForecastQuery(forecast.trace_id);

  const forecastParams = useMemo(
    () =>
      forecastParameters?.filter((parameter) => parameter.type === paramType),
    [forecastParameters, paramType],
  );

  const { currentData: defaultParameters } = useDefaultParametersQuery(
    forecast.trace_id,
  );

  const { currentData: regions = [] } = useGetCurrentRegionsQuery(
    forecast.start_period,
  );

  const uniqueRegionNames = useMemo(
    () =>
      regions
        .map((region) => region.name)
        .filter((name, index, self) => self.indexOf(name) === index)
        .sort((param1, param2) => param1.localeCompare(param2)),
    [regions],
  );

  return useMemo(() => {
    const rowRegionNames = uniqueRegionNames;
    const rows = rowRegionNames.map((regionName) => {
      const defaultParameter = defaultParameters?.[paramType][regionName];
      const forecastParameter = forecastParams?.find((parameter) => {
        if (regionName === GLOBAL_REGION_NAME) {
          return parameter.region_name === '';
        }
        return parameter.region_name === regionName;
      });

      return {
        region_name: regionName,
        actual: defaultParameter ?? 0,
        forecast: Number(forecastParameter?.value ?? defaultParameter ?? 0),
        parameter_trace_id: forecastParameter?.trace_id ?? '',
        parameter_type: paramType,
      };
    });

    const globalValueExists =
      GLOBAL_REGION_NAME in (defaultParameters?.[paramType] ?? {});
    if (globalValueExists) {
      const defaultParameter =
        defaultParameters?.[paramType][GLOBAL_REGION_NAME];
      const forecastParameter = forecastParams?.find(
        (parameter) => parameter.region_name === '',
      );
      if (!forecastParameter) {
        const totalForecast = rows.reduce((sum, row) => sum + row.forecast, 0);
        rows.push({
          region_name: GLOBAL_REGION_NAME,
          actual: defaultParameter ?? 0,
          forecast: rows.length ? totalForecast / rows.length : 0,
          parameter_trace_id: '',
          parameter_type: paramType,
        });
      } else {
        rows.push({
          region_name: GLOBAL_REGION_NAME,
          actual: defaultParameter ?? 0,
          forecast: Number(forecastParameter.value),
          parameter_trace_id: forecastParameter.trace_id,
          parameter_type: paramType,
        });
      }
    }

    return cloneDeep(rows);
  }, [uniqueRegionNames, forecastParams, defaultParameters, paramType]);
};

export const GLOBAL_REGION_NAME = 'Global';

export default useForecastParamEditorRows;
