import type { SortModelItem } from '@ag-grid-community/core';

import { type ContentTypeResponse, type TrialResponse } from 'shared/lib/types';

function getTrialFilterValue(trial: TrialResponse): string {
  return `${trial.indication} • ${trial.study_id}`;
}

export function mapContentTypesToFilterValues(
  contentTypes: ContentTypeResponse[],
): string[] {
  return contentTypes.map((contentType) => contentType.name);
}

export function mapTrialsToFilterValues(trials: TrialResponse[]): string[] {
  return trials.map(getTrialFilterValue);
}

export function mapActionsToBackendValues(): Record<string, number> {
  return { Created: 0, Updated: 1, Deleted: 2 };
}

export function mapActionsToFilterValues(): string[] {
  return ['Created', 'Updated', 'Deleted'];
}

export function mapAgGridSortToHanaqSort(
  sortModel: SortModelItem[],
): string | undefined {
  if (sortModel.length === 0) {
    return undefined;
  }

  return sortModel
    .map(({ sort, colId }) => `${sort === 'asc' ? '-' : ''}${colId}`)
    .join(',');
}
