import type {
  CellEditingStartedEvent,
  NewValueParams,
  ValueFormatterParams,
} from '@ag-grid-community/core';

import {
  getCommentButtonCellConfig,
  getDateCellConfig,
  getGenericCellConfig,
  getNullOrDecimalConfig,
  getPercentCompleteCellConfig,
  getRowNumberConfig,
  getSelectCellConfig,
  getToggleableMoneyCellConfig,
  getToggleablePercentCellConfig,
  makeEditableIf,
} from 'shared/components/ag-grid-cells/config';
import type {
  CondorColDef,
  CondorColGroupDef,
} from 'shared/components/ag-grid/types';

import {
  ACTIVITY_DRIVER_ASSIGNMENTS,
  LTD_EXPENSE,
  MONTHS,
} from 'shared/columnHeaders';
import {
  ActivityUnitDetail,
  ActivityUnitType,
  detailChoicesForUnitType,
} from 'shared/lib/driverUnitTypes';
import type {
  CommentLocationType,
  CommentsCountsGroupedByRowId,
  CroCostCategory,
  RegionAndRegionGroupResponse,
} from 'shared/lib/types';

import type { ApiJSON } from 'shared/api/apiJSON';
type Props = {
  activeTab: string | undefined;
  canEditTrialInfo: boolean;
  commentCountsByRowId: CommentsCountsGroupedByRowId | undefined;
  commentType?: CommentLocationType;
  contractContainerTraceId?: string;
  costCategory: CroCostCategory;
  expenseData: ApiJSON | undefined;
  includeCommentButton?: boolean;
  isGridLocked?: boolean;
  isOpenPeriod: boolean;
  regionsAndRegionGroups?: RegionAndRegionGroupResponse[];
  themeMode: 'dark' | 'light' | undefined;
};
export function processCroExpenseGridColumnDefs(
  props: Props,
): Array<CondorColDef | CondorColGroupDef> {
  const {
    isOpenPeriod,
    expenseData,
    costCategory,
    themeMode,
    canEditTrialInfo,
    activeTab,
    commentCountsByRowId,
    commentType,
    contractContainerTraceId,
    regionsAndRegionGroups,
    isGridLocked,
    includeCommentButton = true,
  } = props;
  const ASSIGNED_REGION_WIDTH = 150;
  const canEditData = isOpenPeriod && canEditTrialInfo && !isGridLocked;

  const columnDefs = [
    {
      headerName: '',
      children: [
        {
          field: 'displayRowNum',
          headerName: 'Row',
          pinned: 'left',
          width: 80,
          ...getRowNumberConfig(),
        },
        {
          field: 'activityDescription',
          headerName: 'Description',
          pinned: 'left',
        },
      ],
    },
    {
      headerName: 'Contract',
      children: [
        { field: 'id', hide: true },
        { field: 'contractVersionTraceId', hide: true },
        { field: 'vendorName', hide: true },
        { field: 'traceId', hide: true },
        { field: 'hasEnrollment', hide: true },
        { field: 'hasTreatment', hide: true },
        { field: 'hasFollowup', hide: true },
        { field: 'serviceCategory', rowGroup: true, hide: true },
        { field: 'region' },
        { field: 'activityCode', headerName: 'Activity Code' },
        { field: 'pizzaUnitType', headerName: 'Contracted Unit Type' },
        {
          field: 'pizzaUnitCount',
          headerName: '# of Units',
          ...getNullOrDecimalConfig(),
        },
        {
          headerName: 'Unit Price',
          ...getToggleableMoneyCellConfig('pizza_unit_price', {
            cellRendererParams: { useEmDashInTotal: false },
          }),
        },
        {
          headerName: 'Total Contract Value',
          ...getToggleableMoneyCellConfig('current_contract_value'),
          aggFunc: 'sum',
        },
      ],
    },
    {
      headerName: ACTIVITY_DRIVER_ASSIGNMENTS,
      children: [
        { field: 'activityDriverTraceId', hide: true },
        {
          field: 'unitType',
          filter: true,
          ...(canEditData && {
            ...getSelectCellConfig({ useEmDashInTotal: false }),
            cellEditorParams: { values: getCroUnitTypeChoices(costCategory) },
            onCellValueChanged(event: NewValueParams) {
              event.node?.setDataValue('unitDetail', null);
            },
            ...makeEditableIf(
              ({ data }) => data && data.type !== 'BOTTOM_LINE_ADJUSTMENT',
              themeMode,
            ),
          }),
        },
        {
          field: 'unitDetail',
          filter: true,
          ...(canEditData && {
            ...getSelectCellConfig({ useEmDashInTotal: false }),
            cellEditorParams(params: CellEditingStartedEvent) {
              return {
                values: detailChoicesForUnitType(
                  params.data.unitType,
                  'CRO',
                ).filter(
                  (unitDetail) =>
                    (unitDetail !== ActivityUnitDetail.ENROLLMENT_PERIOD ||
                      params.data.hasEnrollment === true) &&
                    (unitDetail !== ActivityUnitDetail.TREATMENT_PERIOD ||
                      params.data.hasTreatment === true) &&
                    (unitDetail !== ActivityUnitDetail.FOLLOW_UP_PERIOD ||
                      params.data.hasFollowup === true),
                ),
              };
            },
            ...makeEditableIf(
              ({ data }) =>
                data?.unitType && data.type !== 'BOTTOM_LINE_ADJUSTMENT',
              themeMode,
            ),
          }),
        },
        {
          field: 'startDate',
          ...(canEditData
            ? {
                ...getDateCellConfig({ useEmDashInTotal: false }),
                ...makeEditableIf(
                  ({ data }) => data?.unitDetail === ActivityUnitDetail.CUSTOM,
                  themeMode,
                ),
              }
            : { ...getGenericCellConfig({ useEmDashInTotal: false }) }),
        },
        {
          field: 'endDate',
          ...(canEditData
            ? {
                ...getDateCellConfig({ useEmDashInTotal: false }),
                ...makeEditableIf(
                  ({ data }) => data?.unitDetail === ActivityUnitDetail.CUSTOM,
                  themeMode,
                ),
              }
            : { ...getGenericCellConfig({ useEmDashInTotal: false }) }),
        },
        {
          headerName: 'Assigned Region',
          field: 'assignedRegion',
          width: ASSIGNED_REGION_WIDTH,
          ...(canEditData
            ? {
                editable: true,
                ...getSelectCellConfig(),
                valueFormatter: (params: ValueFormatterParams) =>
                  regionsAndRegionGroups?.find(
                    (regionAndRegionGroup) =>
                      regionAndRegionGroup.trace_id === params.value,
                  )?.name ?? '',
                cellEditorParams: {
                  options: regionsAndRegionGroups?.map(
                    (regionAndRegionGroup) => ({
                      label: regionAndRegionGroup.name,
                      value: regionAndRegionGroup.trace_id,
                    }),
                  ),
                },
              }
            : { ...getGenericCellConfig() }),
        },
        {
          field: 'parameterCalculatedUnitCount',
          headerName: '# of units',
          ...getNullOrDecimalConfig(),
        },
        {
          headerName: 'Unit price',
          ...getToggleableMoneyCellConfig('unit_price', {
            cellRendererParams: { useEmDashInTotal: false },
          }),
        },
      ],
    },
    {
      headerName: LTD_EXPENSE,
      children: [
        {
          field: 'percent_recognized',
          headerName: '% Recognized',
          aggFunc: 'expenseToggleablePercentRecognizedTotal',
          ...getToggleablePercentCellConfig('percent_recognized'),
        },
        {
          field: 'monthlyExpenseUnitsTotal',
          headerName: 'Total LTD units',
          ...getNullOrDecimalConfig(),
          hide: true,
        },
        {
          headerName: 'Total LTD expense',
          aggFunc: 'sum',
          ...getToggleableMoneyCellConfig('total_ltd_expense'),
        },
      ],
    },
    ...(includeCommentButton
      ? [
          {
            ...getCommentButtonCellConfig(
              commentType,
              getCommentSlugForCroTabs(activeTab, contractContainerTraceId),
              commentCountsByRowId,
            ),
          },
        ]
      : []),
  ];

  if (expenseData?.month_list_str_pairs) {
    const monthColumns =
      expenseData.month_list_str_pairs.flatMap((monthStr: string) => {
        if (monthStr.endsWith('_units')) {
          return {
            field: monthStr,
            ...getPercentCompleteCellConfig(),
            ...getNullOrDecimalConfig({
              emptyValueForNodeLevel: costCategory === 'DIRECT_FEES' ? 1 : 0,
            }),
            ...(canEditData && {
              ...makeEditableIf(
                ({ data }) =>
                  data?.unitType === ActivityUnitType.PERCENT_COMPLETE,
                themeMode,
              ),
            }),
          };
        }
        return [
          {
            field: monthStr,
            headerName: monthStr,
            ...(canEditData && {
              ...makeEditableIf(
                ({ data, context }) =>
                  context.currencyViewMode === 'native' &&
                  data?.unitType === ActivityUnitType.AS_INVOICED,
                themeMode,
              ),
            }),
            aggFunc: 'sum',
            ...getToggleableMoneyCellConfig(monthStr),
          },
        ];
      }) ?? [];

    columnDefs.push({ headerName: MONTHS, children: monthColumns });
  }

  return columnDefs;
}

export function getCommentSlugForCroTabs(
  activeTab?: string,
  contractContainerTraceId?: string,
) {
  return `${contractContainerTraceId ?? ''}/${activeTab ?? ''}`;
}

export const getCroUnitTypeChoices = (costCategory: CroCostCategory) =>
  Object.values(ActivityUnitType).filter((value) => {
    if (value === ActivityUnitType.NONE) {
      return false;
    }
    if (
      costCategory !== 'PASS_THROUGH' &&
      value === ActivityUnitType.PATIENT_VISITS
    ) {
      return false;
    }
    return true;
  });
