import type { ICellRendererParams } from '@ag-grid-community/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';

function AgGridStatusCodeCellRenderer(params: ICellRendererParams) {
  const { value } = params;

  if (!value) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      {value < 400 ? (
        <CheckCircleIcon color="success" fontSize="small" />
      ) : (
        <CancelIcon color="error" fontSize="small" />
      )}
      <Box sx={{ ml: 0.5 }}>{value}</Box>
    </Box>
  );
}

export default AgGridStatusCodeCellRenderer;
