import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { selectForecast } from 'forecasting/state/slices/forecastSlice';
import { ForecastParameterType } from 'shared/lib/types';

import { useGetForecastParametersByForecastQuery } from 'shared/api/rtkq/forecastparameters';
import { useDefaultParametersQuery } from 'shared/api/rtkq/forecasts';

function useForecastSiteMonthRows() {
  const forecast = useSelector(selectForecast);
  const { currentData: defaultParameters } = useDefaultParametersQuery(
    forecast.trace_id,
  );
  const { currentData: forecastParameters } =
    useGetForecastParametersByForecastQuery(forecast.trace_id);

  return useMemo(() => {
    const defaultStartUpClinicalDuration =
      defaultParameters?.[ForecastParameterType.STARTUP_CLINICAL_DURATION]
        .start_up_clinical_duration ?? 0;
    const siteMonths = defaultParameters?.[ForecastParameterType.SITE_MONTHS];
    const siteMonthsTotal = Object.values(siteMonths ?? {}).reduce(
      (acc, value) => acc + value,
      0,
    );

    const forecastedStartUpClinicalDurationParam = forecastParameters?.filter(
      (parameter) =>
        parameter.type === ForecastParameterType.STARTUP_CLINICAL_DURATION,
    )?.[0];

    const forecastedStartUpClinicalDuration =
      forecastedStartUpClinicalDurationParam?.value ??
      defaultStartUpClinicalDuration;

    const siteForecastParameters = forecastParameters?.filter(
      (parameter) => parameter.type === ForecastParameterType.SITES,
    );

    const forecastedSiteMonths: Record<string, number> = {};
    let forecastedSiteMonthsTotal = 0;
    for (const siteParam of siteForecastParameters ?? []) {
      forecastedSiteMonths[siteParam.region_name] =
        siteParam.value * forecastedStartUpClinicalDuration;
      forecastedSiteMonthsTotal += forecastedSiteMonths[siteParam.region_name];
    }

    return [
      {
        assumption: 'Start up + clinical duration',
        actual: defaultStartUpClinicalDuration,
        forecast: forecastedStartUpClinicalDuration,
        parameterType: ForecastParameterType.STARTUP_CLINICAL_DURATION,
        parameter_trace_id: forecastedStartUpClinicalDurationParam?.trace_id,
      },
      {
        isEmpty: true,
      },
      ...Object.entries(siteMonths ?? {}).map(([region, value]) => ({
        assumption: `Site months ${region}`,
        actual: value,
        forecast: forecastedSiteMonths[region],
      })),
      {
        assumption: 'Site months global total',
        actual: siteMonthsTotal,
        forecast: forecastedSiteMonthsTotal,
        isTotal: true,
      },
    ];
  }, [defaultParameters, forecastParameters]);
}

export default useForecastSiteMonthRows;
