import { useMemo } from 'react';

import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import {
  getDateCellConfig,
  makeEditableIf,
} from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import { selectForecast } from 'forecasting/state/slices/forecastSlice';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import { ForecastParameterType, TIMELINE_PARAMETERS } from 'shared/lib/types';

import {
  useCreateForecastParameterMutation,
  useUpdateForecastParameterMutation,
} from 'shared/api/rtkq/forecastparameters';

const useForecastParamViewerColDefs = (
  groupName: string,
  valueType: 'date' | 'number',
  editable = true,
): CondorColDef[] => {
  const isEditable =
    useFeatureFlag(
      `forecasting_parameters_editable_${groupName.toLowerCase().split(' ').join('_')}`,
    ) && editable;
  const forecast = useSelector(selectForecast);
  const [createForecastParameter] = useCreateForecastParameterMutation();
  const [updateForecastParameter] = useUpdateForecastParameterMutation();

  const themeMode = useTheme().palette.mode;

  return useMemo(() => {
    const handleParameterUpsert = (
      parameterTraceId: string | undefined,
      regionName: string,
      parameterType: ForecastParameterType,
      rowName: string,
      newValue?: number | string,
    ) => {
      void (async () => {
        if (newValue === undefined) {
          return;
        }

        const isTimelineParam = parameterType in TIMELINE_PARAMETERS;

        if (isTimelineParam) {
          const isStartDate = rowName.toLowerCase().endsWith('start');
          if (!parameterTraceId) {
            await createForecastParameter({
              forecast: forecast.trace_id,
              type: parameterType,
              region_name: 'Global',
              value: 0,
              [isStartDate ? 'start_date' : 'end_date']: newValue,
            }).unwrap();
          } else {
            await updateForecastParameter({
              trace_id: parameterTraceId,
              [isStartDate ? 'start_date' : 'end_date']: newValue,
            }).unwrap();
          }
        } else if (!parameterTraceId) {
          await createForecastParameter({
            forecast: forecast.trace_id,
            type: parameterType,
            region_name: regionName,
            value: newValue as number,
          }).unwrap();
        } else {
          await updateForecastParameter({
            trace_id: parameterTraceId,
            value: newValue as number,
          }).unwrap();
        }
      })();
    };

    return [
      {
        field: 'assumption',
        flex: 1,
        headerName: groupName,
        cellRendererSelector: 'forecastParameterCellRendererSelector',
      },
      {
        field: 'actual',
        flex: 1,
        headerName: 'Contracted',
        valueGetter: (params) => params.data.actual,
      },
      { field: 'parameter_trace_id', hide: true },
      { field: 'region_name', hide: true },
      {
        field: 'forecast',
        flex: 1,
        headerName: 'Forecast',
        valueGetter: (params) =>
          valueType === 'number' && params.data.forecast != null
            ? Math.ceil(params.data.forecast)
            : params.data.forecast,
        ...(isEditable && !forecast.locked
          ? {
              ...makeEditableIf(
                ({ data }) =>
                  !data.isTotal &&
                  // until we implement editing for all types
                  [
                    ForecastParameterType.PATIENTS_COMPLETED,
                    ForecastParameterType.PATIENTS_ENROLLED,
                    ForecastParameterType.PATIENTS_SCREENED,
                    ForecastParameterType.SITES,
                    ForecastParameterType.STARTUP_CLINICAL_DURATION,
                    ForecastParameterType.PATIENT_THROUGHPUT,
                    ForecastParameterType.STARTUP,
                    ForecastParameterType.CONDUCT,
                    ForecastParameterType.ENROLLMENT,
                    ForecastParameterType.TREATMENT,
                    ForecastParameterType.FOLLOWUP,
                    ForecastParameterType.CLOSE,
                  ].includes(data?.parameterType),
                themeMode,
              ),
              ...editableCellParams(valueType),
              onCellValueChanged: (event) => {
                handleParameterUpsert(
                  event.data.parameter_trace_id,
                  event.data.region_name,
                  event.data.parameterType,
                  event.data.assumption,
                  event.newValue,
                );
              },
            }
          : {}),
      },
    ];
  }, [
    groupName,
    forecast,
    createForecastParameter,
    updateForecastParameter,
    valueType,
    themeMode,
    isEditable,
  ]);
};

function editableCellParams(valueType: 'date' | 'number') {
  switch (valueType) {
    case 'number':
      return {
        cellEditor: 'AgGridTextEditor',
        cellEditorParams: { inputType: 'number' },
      };
    case 'date':
      return getDateCellConfig();
  }
}

export default useForecastParamViewerColDefs;
