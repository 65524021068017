import { useMemo } from 'react';

import type { CellValueChangedEvent } from '@ag-grid-community/core';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

import {
  useUpdateForecastParameterMonthMutation,
  useUpdateForecastParameterMutation,
} from 'shared/api/rtkq/forecastparameters';

function usePatientSiteActivityGridOptions(
  _isOpenPeriod: boolean,
): CondorGridOptions {
  const [updateForecastParameter] = useUpdateForecastParameterMutation();
  const [updateForecastParameterMonth] =
    useUpdateForecastParameterMonthMutation();

  async function onCellValueChanged(event: CellValueChangedEvent) {
    const {
      data: { forecast_parameter_id, input_type },
      newValue,
      colDef,
    } = event;
    if (colDef.field === 'input_type') {
      await updateForecastParameter({
        trace_id: forecast_parameter_id,
        input_type,
      }).unwrap();
    } else if (colDef.field?.includes('forecasted_month_')) {
      const month = colDef.field.replace('forecasted_month_', ''); // in the format jan-2014
      await updateForecastParameterMonth({
        trace_id: forecast_parameter_id,
        month,
        value: newValue,
      });
    }
  }

  return useMemo(
    () => ({
      getRowId: 'forecast_parameter_id',
      grandTotalRow: 'bottom',
      getRowStyle: 'highlightRow',
      onCellValueChanged,
    }),
    [],
  );
}

export default usePatientSiteActivityGridOptions;
