import { useEffect, useMemo, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';

import type { AgGridRef } from 'shared/components/ag-grid/CondorAgGrid';
import CommentsButton from 'shared/components/comments/CommentsButton';
import { useCommentsPopup } from 'shared/components/comments/CommentsPopupContext';
import usePageCommentsCountLoader from 'shared/components/comments/hooks/usePageCommentsCountLoader';
import LockGridButton from 'shared/components/lock-grid/LockGridButton';
import LockGridReminder from 'shared/components/lock-grid/LockGridReminder';

import CroContractVersionDetail from 'accruals/pages/clinical-expenses/cro/CroContractVersionDetail';
import useContractAndPeriodWithVersions from 'accruals/pages/clinical-expenses/shared/hooks/useContractAndPeriodWithVersions';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import { CRO_CONTRACT_TABS } from 'shared/helpers/helpers';
import { getCommentSlugForCroTabs } from 'shared/hook-helpers/processCroExpenseGridColumnDefs';
import type { CurrencyViewMode } from 'shared/lib/currency-toggle-group/CurrencyToggleGroup';
import CurrencyToggleGroup from 'shared/lib/currency-toggle-group/CurrencyToggleGroup';
import { CommentLocationType, LockGrid } from 'shared/lib/types';

import type { ApiJSON } from 'shared/api/apiJSON';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

import CroContractOverviewTabContent from './CroContractOverviewTabContent';
import CroContractTabs from './CroContractTabs';
import CroReconExpenseSubtabs from './CroReconExpenseSubtabs';
import CroDirectFeesActivityDriverCounts from './components/CroDirectFeesActivityDriverCounts';
import CroPassThroughActivityDriverCounts from './components/CroPassThroughActivityDriverCounts';
import CroReconGridHeader from './components/CroReconGridHeader';
import CroDirectFeesExpensesGrid from './grids/CroDirectFeesExpensesGrid';
import CroDirectFeesReconGrid from './grids/CroDirectFeesReconGrid';
import CroInvestigatorFeesExpensesGrid from './grids/CroInvestigatorFeesExpensesGrid';
import CroInvestigatorFeesReconGrid from './grids/CroInvestigatorFeesReconGrid';
import CroPassThroughsExpensesGrid from './grids/CroPassThroughsExpensesGrid';
import CroPassThroughsReconGrid from './grids/CroPassThroughsReconGrid';

function getCommentsLocationType(activeTab: string, activeSubTab: string) {
  if (activeTab === CRO_CONTRACT_TABS.DIRECT_FEES) {
    return activeSubTab === 'expense'
      ? CommentLocationType.DIRECT_FEES_EXPENSE
      : CommentLocationType.DIRECT_FEES_RECON;
  }

  if (activeTab === CRO_CONTRACT_TABS.PASS_THROUGHS) {
    return activeSubTab === 'expense'
      ? CommentLocationType.PASS_THROUGHS_EXPENSE
      : CommentLocationType.PASS_THROUGHS_RECON;
  }

  if (activeTab === CRO_CONTRACT_TABS.INVESTIGATOR_FEES) {
    return activeSubTab === 'expense'
      ? CommentLocationType.INVESTIGATOR_FEES_EXPENSE
      : CommentLocationType.INVESTIGATOR_FEES_RECON;
  }

  return CommentLocationType.DIRECT_FEES_EXPENSE;
}

function CroContractContainerPage() {
  const [currencyViewMode, setCurrencyViewMode] =
    useState<CurrencyViewMode>('trial');
  // activetab is one of: 'current-contract' | 'direct-fees' | 'investigator-fees' | 'overview' | 'pass-throughs'
  // activeSubTab is one of: 'expense' | 'recon' | undefined
  const { activeTab, activeSubTab } = useParams();
  const showComments =
    activeTab === CRO_CONTRACT_TABS.DIRECT_FEES ||
    activeTab === CRO_CONTRACT_TABS.PASS_THROUGHS ||
    activeTab === CRO_CONTRACT_TABS.INVESTIGATOR_FEES;

  // do not pass in any "live" values, as it will break closed periods
  const {
    contractVersionPeriodMenuItemTraceId,
    contractContainerTraceId,
    isClosedPeriodVersion,
    liveSlugFromPeriodMenuItem,
  } = useContractAndPeriodWithVersions();

  const gridSx = useMemo(() => ({ height: '100%' }), []);
  const userDisplayOptions = useMemo(
    () => ({ currencyViewMode }),
    [currencyViewMode],
  );

  const locationSlug = getCommentSlugForCroTabs(
    activeTab,
    isClosedPeriodVersion
      ? liveSlugFromPeriodMenuItem
      : contractContainerTraceId,
  );
  const commentsCount = usePageCommentsCountLoader(
    getCommentsLocationType(activeTab ?? '', activeSubTab ?? ''),
    locationSlug,
  );

  const gridRef = useRef<AgGridRef<ApiJSON>>(null);
  const { setGridRef } = useCommentsPopup();

  // biome-ignore lint/correctness/useExhaustiveDependencies: we need to update the grid ref when the active tab changes
  useEffect(() => {
    setGridRef(gridRef.current);
  }, [setGridRef, activeTab, activeSubTab]);

  return (
    <PageContentLoadingContainer
      tabs={
        <Box sx={{ display: 'flex', alignItems: 'stretch' }}>
          <Box sx={{ flex: 1 }}>
            <CroContractTabs />
            {!!activeSubTab && <CroReconExpenseSubtabs />}
          </Box>
        </Box>
      }
    >
      {activeTab === CRO_CONTRACT_TABS.OVERVIEW && (
        <CroContractOverviewTabContent />
      )}
      {activeTab === CRO_CONTRACT_TABS.DIRECT_FEES &&
        activeSubTab === 'expense' && (
          <FlexGridContainer>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              gap={1}
              justifyContent="space-between"
              sx={{ mb: 1 }}
            >
              <CroDirectFeesActivityDriverCounts
                parentMenuItem={contractVersionPeriodMenuItemTraceId}
              />
              <CurrencyToggleGroup
                setValue={setCurrencyViewMode}
                testId="CROExpenseGridToggle"
                value={currencyViewMode}
              />
              <LockGridButton
                grid={LockGrid.CRO_DIRECT_FEES_EXPENSE_GRID}
                locationSlug={null}
              />
              {showComments && (
                <CommentsButton
                  commentsCount={commentsCount}
                  gridRef={gridRef.current}
                  location={CommentLocationType.DIRECT_FEES_EXPENSE}
                  locationSlug={locationSlug}
                />
              )}
            </Box>
            <CroDirectFeesExpensesGrid
              ref={gridRef}
              parentMenuItem={contractVersionPeriodMenuItemTraceId}
              sx={gridSx}
              userDisplayOptions={userDisplayOptions}
            />
            <LockGridReminder
              grid={LockGrid.CRO_DIRECT_FEES_EXPENSE_GRID}
              locationSlug={null}
            />
          </FlexGridContainer>
        )}
      {activeTab === CRO_CONTRACT_TABS.DIRECT_FEES &&
        activeSubTab === 'recon' && (
          <FlexGridContainer>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              gap={1}
              justifyContent="space-between"
              sx={{ mb: 1 }}
            >
              <CroReconGridHeader costCategory="DIRECT_FEES" />
              <CurrencyToggleGroup
                setValue={setCurrencyViewMode}
                testId="CRODirectReconGridToggle"
                value={currencyViewMode}
              />
              <LockGridButton
                grid={LockGrid.CRO_DIRECT_FEES_RECONCILIATION_GRID}
                locationSlug={null}
              />
              {showComments && (
                <CommentsButton
                  commentsCount={commentsCount}
                  gridRef={gridRef.current}
                  location={CommentLocationType.DIRECT_FEES_RECON}
                  locationSlug={locationSlug}
                />
              )}
            </Box>

            <CroDirectFeesReconGrid
              ref={gridRef}
              parentMenuItem={contractVersionPeriodMenuItemTraceId}
              sx={gridSx}
              userDisplayOptions={userDisplayOptions}
            />
            <LockGridReminder
              grid={LockGrid.CRO_DIRECT_FEES_RECONCILIATION_GRID}
              locationSlug={null}
            />
          </FlexGridContainer>
        )}
      {activeTab === CRO_CONTRACT_TABS.PASS_THROUGHS &&
        activeSubTab === 'expense' && (
          <FlexGridContainer>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              gap={1}
              justifyContent="space-between"
              sx={{ mb: 1 }}
            >
              <CroPassThroughActivityDriverCounts
                parentMenuItem={contractVersionPeriodMenuItemTraceId}
              />
              <CurrencyToggleGroup
                setValue={setCurrencyViewMode}
                testId="CROExpenseGridToggle"
                value={currencyViewMode}
              />
              <LockGridButton
                grid={LockGrid.CRO_PASS_THROUGHS_EXPENSE_GRID}
                locationSlug={null}
              />
              {showComments && (
                <CommentsButton
                  commentsCount={commentsCount}
                  gridRef={gridRef.current}
                  location={CommentLocationType.PASS_THROUGHS_EXPENSE}
                  locationSlug={locationSlug}
                />
              )}
            </Box>
            <CroPassThroughsExpensesGrid
              ref={gridRef}
              parentMenuItem={contractVersionPeriodMenuItemTraceId}
              sx={gridSx}
              userDisplayOptions={userDisplayOptions}
            />
            <LockGridReminder
              grid={LockGrid.CRO_PASS_THROUGHS_EXPENSE_GRID}
              locationSlug={null}
            />
          </FlexGridContainer>
        )}
      {activeTab === CRO_CONTRACT_TABS.PASS_THROUGHS &&
        activeSubTab === 'recon' && (
          <FlexGridContainer>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              gap={1}
              justifyContent="space-between"
              sx={{ mb: 1 }}
            >
              <CroReconGridHeader costCategory="PASS_THROUGH" />
              <CurrencyToggleGroup
                setValue={setCurrencyViewMode}
                testId="CROPassReconGridToggle"
                value={currencyViewMode}
              />
              <LockGridButton
                grid={LockGrid.CRO_PASS_THROUGHS_RECONCILIATION_GRID}
                locationSlug={null}
              />
              {showComments && (
                <CommentsButton
                  commentsCount={commentsCount}
                  gridRef={gridRef.current}
                  location={CommentLocationType.PASS_THROUGHS_RECON}
                  locationSlug={locationSlug}
                />
              )}
            </Box>

            <CroPassThroughsReconGrid
              ref={gridRef}
              parentMenuItem={contractVersionPeriodMenuItemTraceId}
              sx={gridSx}
              userDisplayOptions={userDisplayOptions}
            />
            <LockGridReminder
              grid={LockGrid.CRO_PASS_THROUGHS_RECONCILIATION_GRID}
              locationSlug={null}
            />
          </FlexGridContainer>
        )}
      {activeTab === CRO_CONTRACT_TABS.INVESTIGATOR_FEES &&
        activeSubTab === 'expense' && (
          <FlexGridContainer>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              gap={1}
              justifyContent="space-between"
              sx={{ mb: 1 }}
            >
              <CurrencyToggleGroup
                modes={['site', 'native', 'trial']}
                setValue={setCurrencyViewMode}
                testId="CROInvestigatorFeesExpenseToggle"
                value={currencyViewMode}
              />
              {showComments && (
                <CommentsButton
                  commentsCount={commentsCount}
                  gridRef={gridRef.current}
                  location={CommentLocationType.INVESTIGATOR_FEES_EXPENSE}
                  locationSlug={locationSlug}
                />
              )}
            </Box>
            <CroInvestigatorFeesExpensesGrid
              ref={gridRef}
              parentMenuItem={contractVersionPeriodMenuItemTraceId}
              sx={gridSx}
              userDisplayOptions={userDisplayOptions}
            />
          </FlexGridContainer>
        )}
      {activeTab === CRO_CONTRACT_TABS.INVESTIGATOR_FEES &&
        activeSubTab === 'recon' && (
          <FlexGridContainer>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              gap={1}
              justifyContent="space-between"
              mb={2}
            >
              <CurrencyToggleGroup
                modes={['site', 'native', 'trial']}
                setValue={setCurrencyViewMode}
                testId="CROInvestigatorFeesReconToggle"
                value={currencyViewMode}
              />
              <LockGridButton
                grid={LockGrid.CRO_INVESTIGATOR_FEES_RECONCILIATION_GRID}
                locationSlug={null}
              />
              {showComments && (
                <CommentsButton
                  commentsCount={commentsCount}
                  gridRef={gridRef.current}
                  location={CommentLocationType.INVESTIGATOR_FEES_RECON}
                  locationSlug={locationSlug}
                />
              )}
            </Box>
            <CroInvestigatorFeesReconGrid
              ref={gridRef}
              parentMenuItem={contractVersionPeriodMenuItemTraceId}
              sx={gridSx}
              userDisplayOptions={userDisplayOptions}
            />
            <LockGridReminder
              grid={LockGrid.CRO_INVESTIGATOR_FEES_RECONCILIATION_GRID}
              locationSlug={null}
            />
          </FlexGridContainer>
        )}
      {activeTab === CRO_CONTRACT_TABS.CURRENT_CONTRACT && (
        <CroContractVersionDetail />
      )}
      {activeTab === CRO_CONTRACT_TABS.AMENDMENT_IN_PROGRESS && (
        <CroContractVersionDetail />
      )}
    </PageContentLoadingContainer>
  );
}

export default withAccrualPageRequiredData(CroContractContainerPage);
