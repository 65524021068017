import { useMemo } from 'react';

import type {
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from '@ag-grid-community/core';
import { useSelector } from 'react-redux';

import type { TraceId } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import {
  useGetIntegrationTypesQuery,
  useLazyGetIntegrationAuditLogQuery,
} from 'shared/api/rtkq/companies';
import { useGetTrialsByCompanyQuery } from 'shared/api/rtkq/trials';

import {
  mapAgGridSortToHanaqSort,
  mapTrialsToFilterValues,
} from '../../audit-log/hooks/utils';

const TRIAL_COLUMN = 'trial';
const INTEGRATION_TYPE_COLUMN = 'integration_type';

// Audit log is intentionally not period aware as it would be too much data to save
export default function useIntegrationAuditLogServerSideDatasource(): IServerSideDatasource {
  const currentCompany = useSelector(selectCompany);

  const { currentData: trials } = useGetTrialsByCompanyQuery(
    currentCompany.trace_id,
  );

  const { currentData: integrationTypes } = useGetIntegrationTypesQuery(
    currentCompany.trace_id,
  );

  const [getIntegrationAuditLog] = useLazyGetIntegrationAuditLogQuery();

  return useMemo(
    () => ({
      getRows: async (params: IServerSideGetRowsParams) => {
        let filters: Record<
          string,
          number[] | TraceId | TraceId[] | number | undefined
        > = {};

        if (
          integrationTypes &&
          params.request.filterModel &&
          INTEGRATION_TYPE_COLUMN in params.request.filterModel
        ) {
          const integrationTypeFilters =
            params.request.filterModel[INTEGRATION_TYPE_COLUMN];

          const finalIntegrationTypeFilters = [];
          for (const filter of integrationTypeFilters.values) {
            const integrationTypeIndex = integrationTypes.indexOf(filter);
            if (integrationTypeIndex >= 0) {
              finalIntegrationTypeFilters.push(
                integrationTypes[integrationTypeIndex],
              );
            }
          }

          filters = {
            ...filters,
            integration_type: finalIntegrationTypeFilters,
          };
        }

        if (
          trials &&
          params.request.filterModel &&
          TRIAL_COLUMN in params.request.filterModel
        ) {
          const allTrialFilters = mapTrialsToFilterValues(trials);
          const trialFilters = params.request.filterModel[TRIAL_COLUMN];

          const finalTrialFilters = [];
          for (const filter of trialFilters.values) {
            const trialIndex = allTrialFilters.indexOf(filter);
            if (trialIndex >= 0) {
              finalTrialFilters.push(trials[trialIndex].trace_id);
            }
          }

          filters = {
            ...filters,
            trial: finalTrialFilters,
          };
        }

        try {
          const auditLog = await getIntegrationAuditLog({
            trace_id: currentCompany.trace_id,
            filters,
            page: Math.ceil((params.request.endRow ?? 100) / 100),
            sort: mapAgGridSortToHanaqSort(params.request.sortModel),
          }).unwrap();
          params.success({ rowData: auditLog.rows, rowCount: auditLog.count });
        } catch {
          params.fail();
        }
      },
    }),
    [currentCompany, integrationTypes, trials, getIntegrationAuditLog],
  );
}
